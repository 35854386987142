import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { environment, environmentReceta } from '@environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import {
  ConfiguracionState,
  datosInicioState,
  datosTransaccionesState,
  recetasState,
  UIState,
} from '@store/models/store.models';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Dispensa, SoftwareRequestModel } from '../models/datosTransaccion.models';
import { version } from '../../../package.json';
//import { Fechas } from '@helpers/fechas';

@Injectable({
  providedIn: 'root',
})
export class TransaccionesService {
  private subscriptionStore: Subscription = new Subscription();
  private headers: HttpHeaders = new HttpHeaders({});
  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState: ConfiguracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  rescatarRecetas(datosInicio: datosInicioState): Observable<any> {

    let mensajeRR = {
      terminal: "NUVALID",
      cuitPrestador: datosInicio.PrestadorRequest?.CuitPrestador,
      cuitFinanciador: datosInicio.FinanciadorRequest?.CuitFinanciador,
      trackCredencial: datosInicio.CredencialRequest?.NumeroCredencial

    }

    return this.http.post<any>(
      environment.url + `/transaccion/rescatarRecetas/${datosInicio.PrestadorRequest?.Licencia}`, mensajeRR,
      { headers: this.headers }
    ).pipe(take(1));
  }

  // /transaccion/rescatarReceta/

  rescatarReceta(datosInicio: datosInicioState, datosReceta: recetasState): Observable<any> {

    let mensajeRR = {
      terminal: "NUVALID",
      cuitPrestador: datosInicio.PrestadorRequest?.CuitPrestador,
      cuitFinanciador: datosInicio.FinanciadorRequest?.CuitFinanciador,
      trackCredencial: datosInicio.CredencialRequest?.NumeroCredencial,
      nroFormulario: datosReceta.RecetasRequest?.Id
    }
    console.log('mensajeRR', mensajeRR);

    return this.http.post<any>(
      environment.url + `/transaccion/rescatarReceta/${datosInicio.PrestadorRequest?.Licencia}`, mensajeRR,
      { headers: this.headers }
    ).pipe(take(1));
  }

  realizarTransaccion(datosInicio: datosInicioState, datosTransaccion: datosTransaccionesState, parametrosPrescribir: any = {}, archivo: any = null): Observable<any> {
    if(datosInicio.TransaccionRequest?.CodigoTransaccion !== '02R'  || parametrosPrescribir.verificoPrescripcion) { //codigo provisorio para realizar receta electronica
      let mensajeAEnviar = {
        DetalleMedicamentos: datosTransaccion.DetalleMedicamentosRequest,
        DetalleProcedimientos: datosTransaccion.DetalleProcedimientoRequest,
        EncabezadoAtencion: {
          Acompannante: datosTransaccion.AcompannanteRequest,
          Atencion: datosTransaccion.AtencionRequest,
          Beneficiario: datosTransaccion.BeneficiarioRequest,
          CodFinalizacionTratamiento:
            datosTransaccion.CodFinalizacionTratamientoRequest,
          Credencial: datosInicio.CredencialRequest,
          Derivacion: datosTransaccion.DerivacionRequest,
          Diagnostico: datosTransaccion.DiagnosticosRequest,
          Documentacion: datosTransaccion.DocumentacionRequest,
          Efector: datosTransaccion.EfectorRequest,
          MensajeParaFinanciador: datosTransaccion.MensajeParaFinanciadorRequest,
          Preautorizacion: datosTransaccion.PreautorizacionRequest,
          Prescripcion: datosTransaccion.PrescripcionRequest,
          Prescriptor: datosTransaccion.PrescriptorRequest,
        },
        EncabezadoMensaje: {
          FechaReferenciaCancel: datosTransaccion.FechaReferenciaCancelRequest,
          Financiador: datosInicio.FinanciadorRequest,
          IdMsj: datosTransaccion.IdMsjRequest,
          InicioTrx: datosTransaccion.InicioTrxRequest,
          NroReferenciaCancel: datosTransaccion.NroReferenciaCancelRequest,
          NroReferencia: datosTransaccion.NroReferenciaRequest,
          Prestador: datosInicio.PrestadorRequest,
          Software: new SoftwareRequestModel('NUVALID', version),
          Terminal: datosTransaccion.TerminalRequest,
          TipoTransaccion: parametrosPrescribir.verificoPrescripcion ? '01A' : datosInicio.TransaccionRequest?.CodigoTransaccion,
          VersionMsj: datosTransaccion.VersionMsjRequest
        },
      };
      console.log('mensajeAEnviar: ', mensajeAEnviar);

      return this.http.post(
          environment.url + `/transaccion/${datosInicio.PrestadorRequest?.Licencia}`, mensajeAEnviar,
          { headers: this.headers, params: { file: archivo }, }
          ).pipe(take(1));

    } else {
      console.log('entre al else');

      let objetDispensa: Dispensa = {
        //Beneficiario
        firma_olografa: datosTransaccion.extraDispensaRequest?.firma_olografa || '',
        beneficiario_nombre: datosTransaccion.BeneficiarioRequest?.NombreBeneficiario || null,
        beneficiario_apellido : datosTransaccion.BeneficiarioRequest?.ApellidoBeneficiario || null,
        beneficiario_sexo : datosTransaccion.BeneficiarioRequest?.Sexo || null,
        beneficiario_fecha_nac: this.formatDate(datosTransaccion.BeneficiarioRequest?.FechaNacimiento!) || null,
        beneficiario_plan : datosTransaccion.BeneficiarioRequest?.PlanCredencial || null,
        beneficiario_email : datosTransaccion.BeneficiarioRequest?.Email || null,

        beneficiario_dni: datosTransaccion.extraDispensaRequest?.beneficiario_dni || null,
        credencial_numero :datosInicio.CredencialRequest?.NumeroCredencial || null,
        estado: datosTransaccion.extraDispensaRequest?.estado || 'S',
        diagnostico:  datosTransaccion.extraDispensaRequest?.diagnostico || '',
        //Dispensa
        numero_copia : datosTransaccion.extraDispensaRequest?.numero_copia,
        periodo_autorizado: datosTransaccion.extraDispensaRequest?.periodo_autorizado,
        tratamiento_prolongado: Number(datosTransaccion.extraDispensaRequest?.tratamiento_prolongado) || 0,

        fecha_receta : datosTransaccion.extraDispensaRequest?.fecha_receta || null,
        fecha_dispensa_fin : datosTransaccion.extraDispensaRequest?.fecha_dispensa_fin || null,
        multi_dispensa: Number(datosTransaccion.extraDispensaRequest?.multi_dispensa) || 0,
        financiador_cuit : datosInicio.FinanciadorRequest?.CuitFinanciador || null,
        //Prescriptor
        prescriptor_nombre : datosInicio.PrestadorRequest?.RazonSocial || null,
        prescriptor_apellido : '',
        prescriptor_email : datosInicio.PrestadorRequest?.email_verificacion || null,
        prescriptor_copia :  Number(datosTransaccion.extraDispensaRequest?.prescriptor_copia) || 0,
        prescriptor_domicilio : '',
        prescriptor_telefono : datosInicio.PrestadorRequest?.telefono_verificacion || null,
        prescriptor_especialidad_descripcion : datosTransaccion.PrescriptorRequest?.EspecialidadPrescriptor || null,
        prescriptor_nro_matricula : datosTransaccion.extraDispensaRequest?.matricula?.numero || null,
        prescriptor_tipo_matricula: datosTransaccion.extraDispensaRequest?.matricula?.tipo || null,
        prescriptor_provincia: datosTransaccion.extraDispensaRequest?.matricula?.provincia || null,
        prescriptor_cuit : datosInicio.PrestadorRequest?.CuitPrestador || null,
        prescriptor_tipo_prescriptor: 'M',
        location: { lat: parametrosPrescribir.coordenadas?.lat || null, lng: parametrosPrescribir.coordenadas?.lng || null},
        prescripcion: {
          referencias_rx: datosTransaccion.DetalleMedicamentosRequest || [],
          determinaciones: datosTransaccion.DetalleEstudiosRequest || []
        }
      };

      console.log('dispensa a enviar!!!: ', objetDispensa);
      return this.postDispensa({token: parametrosPrescribir.token, mensaje: objetDispensa}).pipe(take(1));

    }
  }


  // Función para formatear la fecha
  private formatDate(fecha: string): string | null {
    if (!fecha || fecha.length !== 8) return null;

    const year = fecha.substring(0, 4);
    const month = fecha.substring(4, 6);
    const day = fecha.substring(6, 8);

    return `${day}/${month}/${year}`;
  }

  postDispensa(parametros:any) {
    const formData = new FormData();
    formData.append('mensaje', parametros.mensaje);
    let urlNuvalid = environment.url
    return this.http.post(`${urlNuvalid}/transaccion/enviarPrescripcion`, parametros.mensaje, {headers: this.headers});
  }
}

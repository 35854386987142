import { Injectable } from '@angular/core';
import { Ticket } from '@models/datosTicket.models';
import { codigoInstalacion } from '@models/global.models';
import { DataUsuario } from '@models/operaciones.models';
import { Prestador } from '@models/prestador.model';
import { NgbModalConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { datosInicioState, datosTransaccionesState } from '@store/models/store.models';
import { CambiarContrasennaModalComponent } from 'app/modals/cambiar-contrasenna-modal/cambiar-contrasenna-modal.component';
import { ConfirmarModalComponent } from 'app/modals/confirmar-modal/confirmar-modal.component';
import { ConfirmarPrestadoresModalComponent } from 'app/modals/confirmar-prestadores-modal/confirmar-prestadores-modal.component';
import { solicitarInfoModalComponent } from 'app/modals/solicitar-info-modal/solicitar-info-modal.component';
import { TicketModalComponent } from 'app/modals/ticket-modal/ticket-modal.component';
import { VerAdministradoresModalComponent } from 'app/modals/ver-administradores-modal/ver-administradores-modal.component';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { TransaccionesService } from './transacciones.service';
import { GlobalService } from './global.service';
import { Store } from '@ngrx/store';
import { ActivarLoading, DesactivarLoading } from '../store/actions/ui.actions';
import { IpcService } from './ipc.service';
import { InfoFinanciadoresComponent } from '../modals/info-financiadores/info-financiadores.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CscModalComponent } from 'app/modals/csc-modal/csc-modal.component';
import { NewVersionModalComponent } from 'app/modals/new-version-modal/new-version-modal.component';
import { WorkInProgressModalComponent } from 'app/modals/work-in-progress-modal/work-in-progress-modal.component';
import { DownloadModalComponent } from 'app/modals/download-modal/download-modal.component';
import { SaveMatriculaModalComponent } from 'app/modals/save-matricula-modal/save-matricula-modal.component';
import { CannotPrescribeModalComponent } from 'app/modals/cannot-prescribe-modal/cannot-prescribe-modal.component';
import { NewChangesModalComponent } from 'app/modals/new-changes-modal/new-changes-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  preventAlertFromFire: Boolean = false; //Se usa para evitar nuevas alertas mientras se informa nueva versión del sistema
  private subscriptionStore: Subscription = new Subscription();
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private toast: ToastrService,
    private globalService: GlobalService,
    private store: Store,
    private transaccionesService: TransaccionesService,
    private ipcService: IpcService,
    private router: Router
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
  }

  cargando(msj: string = 'Espere por favor...') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: msj,
    });
    Swal.showLoading();
  }

  timeLoading(msj: string = 'Espere por favor...') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: msj,
      timer: 1500
    })
    Swal.showLoading();
  }

  cerrar() {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    Swal.close();
  }

  success(title: string = '', msj: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }

    const swalWithNuvalidButtons = Swal.mixin({
      customClass: {
        confirmButton: 'buttonImprimirTickets',
        cancelButton: 'buttonAnularTicket'
      },
      buttonsStyling: false
    })

    return swalWithNuvalidButtons.fire({
      icon: 'success',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  }

  successMatricula(title: string = '', msj: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }

    const swalWithNuvalidButtons = Swal.mixin({
      customClass: {
        confirmButton: 'buttonImprimirTickets',
        cancelButton: 'buttonAnularTicket'
      },
      buttonsStyling: false
    });

    return swalWithNuvalidButtons.fire({
      icon: 'success',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['inicio']);
        if (this.router.url === '/inicio') {
          window.location.reload();
        }
      }
    });
  }

  info(title: string = '', msj: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }

    const swalWithNuvalidButtons = Swal.mixin({
      customClass: {
        confirmButton: 'buttonImprimirTickets',
        cancelButton: 'buttonAnularTicket'
      },
      buttonsStyling: false
    })

    return swalWithNuvalidButtons.fire({
      icon: 'info',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  }

  infoHTML(title: string = '', msj: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    return Swal.fire({
      icon: 'info',
      title: title,
      html: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  }

  informarNuevaVersion(title: string = '', msj: string = '') {
    this.preventAlertFromFire = true;
    return Swal.fire({
      icon: 'info',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  }

  error(title: string = '', msj: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    return Swal.fire({
      icon: 'error',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      backdrop: '#546289',
      allowOutsideClick: false,
    });
  }

  advertencia(title: string, msj?: string) {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    return Swal.fire({
      icon: 'warning',
      title: title,
      text: msj,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  }

  errorHTML(html: string = '') {
    while (this.preventAlertFromFire) {
      setTimeout(() => { }, 30000);
    }
    return Swal.fire({
      icon: 'error',
      html: html,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      backdrop: '#546289',
      allowOutsideClick: false,
    });
  }

  confirmarPrestadoresModal(prestadores: Array<Prestador>) {
    let modal = this.modalService.open(ConfirmarPrestadoresModalComponent, { size: 'xl', backdrop: false, centered: true, animation: true, keyboard: false });
    modal.componentInstance.prestadores = prestadores;
    return modal;
  }

  verAdministradoresModal(administradores: Array<DataUsuario>) {
    let modal = this.modalService.open(VerAdministradoresModalComponent, { size: 'lg' });
    modal.componentInstance.administradores = administradores;
  }

  verFinanciadoresModal(financiadores: Array<any>) {
    let modal = this.modalService.open(InfoFinanciadoresComponent);
    modal.componentInstance.financiadores = financiadores;
  }

  verDatosDeUsuarioModificado(
    administradores: Array<DataUsuario>,
    titulo: string,
    funcion: string
  ): any {
    let modal = this.modalService.open(VerAdministradoresModalComponent);
    modal.componentInstance.administradores = administradores;
    modal.componentInstance.titulo = titulo;
    modal.componentInstance.funcion = funcion;
    return modal;
  }

  get isInstace() {

    return this.modalService.hasOpenModals();
  }

  solicitarInfoModal(
    MensajeAMostrar: string,
    TipoDatosAPedir: string,
    datos: codigoInstalacion | undefined = undefined,
    lugaresDeAtencion: Array<codigoInstalacion> | undefined = undefined
  ) {
    let modal = this.modalService.open(solicitarInfoModalComponent);
    modal.componentInstance.mensajeAMostrar = MensajeAMostrar;
    modal.componentInstance.tipoDatosAPedir = TipoDatosAPedir;
    if (datos) {
      modal.componentInstance.datos = datos;
    }
    if (lugaresDeAtencion) {
      modal.componentInstance.lugaresDeAtencion = lugaresDeAtencion;
    }
    return modal;

  }

  cscModal() {
    let modal = this.modalService.open(CscModalComponent);
    return modal;
  }

  downloadModal() {
    let modal = this.modalService.open(DownloadModalComponent, { centered: true });
    return modal;
  }

  cambiarContrasennaModal() {
    let modal = this.modalService.open(CambiarContrasennaModalComponent);
    return modal;
  }

  confirmarModal(titulo: string) {
    let modal = this.modalService.open(ConfirmarModalComponent);
    modal.componentInstance.titulo = titulo;
    return modal;
  }

  newVersionModal() {
    let modal = this.modalService.open(NewVersionModalComponent, { size: 'lg', windowClass: "containerNewVersionModal", centered: true, animation: true, backdropClass: 'backdropBlur', keyboard: false });
    return modal;
  }

  workInProgressModal() {
    let modal = this.modalService.open(WorkInProgressModalComponent, { size: 'lg', windowClass: "containerWorkInProgressModal", centered: true, animation: true, backdropClass: 'backdropBlur' });
    return modal;
  }

  newChangesModal() {
    let modal = this.modalService.open(NewChangesModalComponent, { size: 'lg', windowClass: "containerNewChangesModal", centered: true, animation: true, backdropClass: 'backdropBlur' });
    return modal;
  }

  saveMatriculaModal() {
    let modal = this.modalService.open(SaveMatriculaModalComponent, { centered: true });
    return modal;
  }

  cannotPrescribeModal() {
    let modal = this.modalService.open(CannotPrescribeModalComponent, { centered: true });
    return modal
  }

  toastSuccess(mensaje: string) {
    this.toast.success(mensaje);
  }

  toastError(mensaje: string) {
    /* this.toast.error(mensaje); */
    this.toast.error(mensaje);
  }

  toastWarning(mensaje: string) {
    this.toast.warning(mensaje);
  }

  showTicketModal(
    nombreTransaccion: string,
    ticket: Ticket,
    datosInicio: datosInicioState,
    datosTransaccion: datosTransaccionesState
  ) {
    let modal = this.modalService.open(TicketModalComponent);
    modal.componentInstance.datosTicket = ticket;
    modal.componentInstance.nombreTransaccion = nombreTransaccion;
    modal.componentInstance.datosInicio = datosInicio;
    modal.componentInstance.datosTransaccion = datosTransaccion;
    modal.result.then((respuesta: any) => {
      if (respuesta && respuesta.respuesta) {
        switch (respuesta.respuesta) {
          case 'IMPRIMIR':
            this.imprimirTicket(ticket);
            break;

          case 'ANULAR':
            this.anualarTransaccion(datosTransaccion, datosInicio, ticket);
            break;

          default:
            break;
        }
      }
    });
  }

  private imprimirTicket(ticket: Ticket) {
    this.ipcService.imprimirTicket(ticket);
  }

  private anualarTransaccion(
    datosTransaccion: datosTransaccionesState,
    datosInicio: datosInicioState,
    ticket: Ticket
  ) {
    let datosTransaccionNuevo = { ...datosTransaccion };
    console.log('datosTransaccionNuevo', datosTransaccionNuevo);

    let datosInicioNuevo = {
      ...datosInicio,
      TransaccionRequest: { ...datosInicio.TransaccionRequest! },
    };
    console.log('datosInicioNuevo', datosInicioNuevo);
    datosTransaccionNuevo.NroReferenciaCancelRequest =
      ticket.EncabezadoMensaje.NroReferencia;
    datosInicioNuevo!.TransaccionRequest!.CodigoTransaccion = '04A';
    this.store.dispatch(new ActivarLoading());
    this.transaccionesService
      .realizarTransaccion(datosInicioNuevo, datosTransaccionNuevo)
      .subscribe(
        (respuesta) => {
          this.store.dispatch(new DesactivarLoading());
          if (respuesta) {
            console.log('la respuesta del anular transacción', respuesta);
            this.showTicketModal(
              'ANULAR TRANSACCIÓN',
              respuesta,
              datosInicioNuevo!,
              datosTransaccionNuevo!
            );
          } else {
            this.toastError(
              'Se produjo un error, vuelva a intentarlo más tarde'
            );
          }
        },
        (error) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  anularTransaccion(
    datosTransaccion: datosTransaccionesState,
    datosInicio: datosInicioState,
    prestadores: Array<Prestador>,
    ticket: any
  ) {
    let datosTransaccionNuevo = { ...datosTransaccion };
    let datosInicioNuevo = {
      ...datosInicio,
      TransaccionRequest: { ...datosInicio.TransaccionRequest! },
      FinanciadorRequest: { ...datosInicio.FinanciadorRequest! },
      PrestadorRequest: { ...datosInicio.PrestadorRequest! },
      CredencialRequest: { ...datosInicio.CredencialRequest! },
    };
    datosTransaccionNuevo.NroReferenciaCancelRequest = ticket.nroReferencia;
    datosInicioNuevo!.TransaccionRequest!.CodigoTransaccion = '04A';

    // Encontrar el prestador
    let presta = prestadores.find(
      (x) => x.cuit === ticket.cuitPrestador &&
             x.financiadores.some(f => f.cuit_financiador === ticket.cuitFinanciador)
    );

    console.log('ticket.cuitPrestador', ticket.cuitPrestador);

    if (!presta) {
      console.error('No se encontró un prestador para el CUIT proporcionado:', ticket.cuitPrestador);
      return;
    }

    datosInicioNuevo.CredencialRequest.NumeroCredencial = ticket.credencialAfiliado;
    datosInicioNuevo.PrestadorRequest.CuitPrestador = presta.cuit;

    // Verificar financiadores
    if (!presta.financiadores || presta.financiadores.length === 0) {
      console.error('El prestador no tiene financiadores.');
      return;
    }

    // Buscar el financiador
    let finan = presta.financiadores.find(
      (f) => f.cuit_financiador === ticket.cuitFinanciador
    );

    if (!finan) {
      console.error('No se encontró un financiador con el CUIT proporcionado:', ticket.cuitFinanciador);
      return;
    }

    datosInicioNuevo.FinanciadorRequest.CodigoFinanciador = finan.codigo_empresa;
    datosInicioNuevo.FinanciadorRequest.CuitFinanciador = finan.cuit_financiador;

    datosInicioNuevo!.PrestadorRequest!.Licencia = finan.licencia;
    datosInicioNuevo!.PrestadorRequest!.CuitFinanciador = finan.cuit_financiador;
    datosInicioNuevo!.PrestadorRequest!.email_verificacion = presta.email_verificacion;

    this.store.dispatch(new ActivarLoading());
    this.transaccionesService
      .realizarTransaccion(datosInicioNuevo, datosTransaccionNuevo)
      .subscribe(
        (respuesta) => {
          this.store.dispatch(new DesactivarLoading());
          if (respuesta) {
            console.log('la respuesta del anular transacción', respuesta);
            this.showTicketModal(
              'ANULAR TRANSACCIÓN',
              respuesta,
              datosInicioNuevo!,
              datosTransaccionNuevo!
            );
          } else {
            this.toastError(
              'Se produjo un error, vuelva a intentarlo más tarde'
            );
          }
        },
        (error) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

}

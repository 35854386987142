<div class="container__datosInit">
  <nav class="navbar navbar-light ">
    <h4 class="tittle__dashboard">Tickets</h4>
    <div style="padding: 1rem 3rem 0rem 0rem">
      <button class="btn btnDownload" (click)="openModal()">Descargar reporte</button>
    </div>
  </nav>
  <div class="filtrosTicket">
    <form [formGroup]="form" class="form-infoN">
      <!-- START FECHAS -->
      <div class="row" style="width: 100%;">
        <div class="col">
          <div class="form-infoN">
            <label>Fecha desde: </label>
            <input type="text" placeholder="DD/MM/YYYY" class="form-controlN form-controlN-dashboardP" bsDatepicker
              [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true}"
              formControlName="FechaDesde">
          </div>
        </div>
        <div class="col">
          <div class="form-infoN">
            <label>Fecha hasta: </label>
            <input type="text" placeholder="DD/MM/YYYY" class="form-controlN form-controlN-dashboardP" bsDatepicker
              [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true}"
              formControlName="FechaHasta">
          </div>
        </div>

        <!-- END FECHAS -->

        <!-- START FILTROS -->
        <div class="col form-infoN">
          <label>Financiador: </label>
          <select type="text" class="form-controlN" formControlName="Financiador">
            <option [ngValue]="ft.cuit" *ngFor="let ft of finanTicket">
              {{ ft.sigla }}
            </option>
          </select>
        </div>
        <div class="col form-infoN">
          <label>Prestador: </label>
          <select type="text" class="form-controlN" formControlName="Prestador">
            <option [ngValue]="prestador.cuit" *ngFor="let prestador of prestadores | uniqueCuit">
              {{ prestador.razon_social }}
            </option>
          </select>
        </div>
        <div class="col form-infoN">
          <label>Afiliado: </label>
          <input maxlength="23" autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
            class="form-controlN" formControlName="Afiliado">
        </div>
      </div>
      <!-- END FILTROS -->
      <div>
        <button class="buttonBuscarTickets" (click)="guardar()">
          Buscar
        </button>
      </div>
    </form>
  </div>

  <div class="card-body rounded p-5">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="bg-light">
          <th class="text-center">Número</th>
          <th class="text-center">Fecha</th>
          <th class="text-center">Operación</th>
          <th class="text-center">Afiliado</th>
          <th class="text-center">Prestador</th>
          <th class="text-center">Financiador</th>
          <th class="text-center">Ver</th>
          <th class="text-center">Anular</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let ticket of tickets">
            <tr>
              <ng-container *ngIf="ticket.siglaFinanciador === 'OSDE'; else otroFinanciador">
                <td class="text-center">
                  {{ ticket.nroReferencia| slice:-6 }}
                    <!-- <span class="tooltiptextqwe">{{ ticket.mensajePrinter }}</span> -->
                </td>
              </ng-container>
              <ng-template #otroFinanciador>
                <td class="text-center">
                  {{ ticket.nroReferencia }}
                </td>
              </ng-template>
              <td class="text-center">
                {{ ticket.fecha | date: 'dd/MM/yyyy' : 'UTC' }}
              </td>
              <td class="text-center">
                {{ ticket.descripcionOperacion}}
              </td>
              <td class="text-center">
                {{ ticket.credencialAfiliado }}
              </td>
              <td class="text-center">
                {{ ticket.cuitPrestador }}
              </td>
              <td class="text-center">
                {{ ticket.siglaFinanciador }}
              </td>
              <td class="text-center">
                <button (click)="imprimirTicket(ticket)" class="buttonImprimirTickets">Ver</button>
              </td>
              <td class="text-center">
                <button (click)="anularTicket(ticket)" class="buttonAnularTicket">Anular</button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <ngb-pagination class="d-flex justify-content-end" style="padding-right: 3em;" [maxSize]="4" [(page)]="page"
    [pageSize]="pageSize" [collectionSize]="totalElements" (pageChange)="getTickets()" ng-show="blocked">
  </ngb-pagination>
</div>

<div class="container__prescripcion">

  <div class="operacionNormalMore"
    style="display: flex; justify-content: center; align-items: center; align-content: center;">
    <p class="operacion__text">{{item?.descripcion}}</p>
  </div>

</div>
<form [formGroup]="form" class="protoQuir">
  <div class="modal-body">
    <!---------DETALLE INDICACIONES---------->
    <div class="form-group">
      <div class="col ">
        <p><strong>Monodroga: </strong>{{medicamento?.droga_descripcion}}</p>
        <p><strong>Laboratorio: </strong>{{medicamento?.laboratorio}}</p>
      </div>
      <div class="col ">
        <p><strong>Acción: </strong>{{medicamento?.accion}}</p>
        <p><strong>Presentación: </strong>{{medicamento?.generico_presentacion}}</p>
      </div>
      <div class="col detalleIndicacion">
        <p><strong>Unidades: </strong>{{medicamento?.unidades}}</p>
      </div>
    </div>
    <div class="form-infoN" style="margin-top: 45px;">
      <textarea rows="5" autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
        class="form-controlN" formControlName="indicacion" style="width: 100%; resize: none;"
        placeholder="Ejemplo de indicación: Tomar 1 (uno) comprimido cada 12 (doce) horas."></textarea>
    </div>
    <div class="form-infoN" style="margin-top: 90px; margin-bottom: 50px;">
      <textarea rows="5" autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
        class="form-controlN" formControlName="precaucion" style="width: 100%; resize: none;"
        placeholder="Ingresar precauciones."></textarea>
    </div>
    <div style="display: flex; gap: 285px;">
      <button class="buttonAnularTicket" (click)="closeModal()" type="button">Cerrar</button>
      <button class="buttonImprimirTickets" (click)="grabarIndicaciones()" type="button">Indicar</button>
    </div>
  </div>
</form>

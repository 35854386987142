import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setEstadoFormDatosEnvioPrescripcionEmailBeRequest, setEstadoFormDetallesMedicamentosRequest, setNuevoToken } from '@store/actions';
import { NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetalleEstudiosRequestModel, DetalleMedicamentosRequestModel, ExtrasDispensa, Item, PrescriptorRequestModel } from '../../../../models/datosTransaccion.models';
import {
  setDetallesEstudiosRequest,
  setDetallesMedicamentosRequest,
  unsetDetallesEstudiosRequest,
  unsetDetallesMedicamentosRequest
} from '../../../../store/actions/datosTransacciones.actions';

import { Fechas } from '@helpers/fechas';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { emailValido } from '@helpers/emailValido';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CredencialRequestModel, Matricula, PrestadorRequestModel } from '@models/datosInicio.models';
import { setExtraDispensaRequest, unsetExtraDispensaRequest } from '../../../../store/actions/datosTransacciones.actions';
import { AgendaService } from '@services/agenda.service';
import { FinanciadorRequestModel } from '../../../../models/datosInicio.models';
import { DatosAfiliadoAgendaModel } from '@models/agenda.models';
import { take } from 'rxjs/operators';
import { setEstadoFormDatosEnvioPrescripcionDiagRequest, setEstadoFormFirmaRequest, setEstadoFormPrescriptorRequest } from '../../../../store/actions/estadoFormsinicio.action';
import { setEstudioSeleccionadoRequest, setMedicamentoSeleccionadoRequest, unsetMedicamentoSeleccionadoRequest } from '../../../../store/actions/datosDispensa.actions';
import { PersistenciaService } from '@services/persistencia.service';
import { AuthService } from '@services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '@services/global.service';
import bootstrap = require('bootstrap');

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-prescripcion-medicamento',
  templateUrl: './prescripcion-medicamento.component.html',
  styleUrls: ['./prescripcion-medicamento.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})

export class PrescripcionMedicamentoComponent implements OnInit {
  valuedate = new Date();
  model: NgbDateStruct | undefined;
  detallesMedicamentos: Array<DetalleMedicamentosRequestModel> | null = null;
  detallesEstudios: Array<DetalleEstudiosRequestModel> | null = null;
  extraDispensa: ExtrasDispensa | null = null;
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionMedicamentosStore: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();
  private subscriptionDatosInicioStore: Subscription = new Subscription();
  prestadorSelecionado: PrestadorRequestModel | null = null;
  credencialSelecionada: CredencialRequestModel | null = null;
  finanaciadorSeleccionado: FinanciadorRequestModel | null = null;
  inicioPrescripcion = false;
  provinciaMatric: string | undefined;
  tipoMatric: string | undefined;

  @ViewChild("checkboxJustifica") checkboxJustifica!: ElementRef;
  @ViewChild("checkboxMultidispensa") checkboxMultidispensa!: ElementRef;
  @ViewChild("checkboxProlongado") checkboxProlongado!: ElementRef;
  @ViewChild("checkboxEnviaCopia") checkboxEnviaCopia!: ElementRef;
  @ViewChild("selectMatricula") selectMatricula!: ElementRef;

  isChecked = false;
  objFechas = new Fechas();
  fecha30 = '';
  fechaFinAPI = '';
  fechaHoyAPI = '';
  minDate: any = this.objFechas.fecha6;
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  selectMatri!: Matricula | [];
  form: IFormGroup<any> =
    this.formBuilder.group({
      emailBeneficiario: new UntypedFormControl(<ControlState<string>>null, [Validators.required, emailValido, blankSpaceValidator]),
      dniPaciente: new UntypedFormControl(<ControlState<string>>null, [Validators.required])
    });
  formul: IFormGroup<any> =
    this.formBuilder.group({
      ctdad: new UntypedFormControl(<ControlState<number>>1, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.pattern('[1-9]'),
        Validators.min(1),
        Validators.max(99),
        blankSpaceValidator])
    });
  firma: any | null;
  botonDeshabilitado: boolean = true;
  agregarMedicamentoDeshabilitado = false;
  existeFirma: string = '';

  provinciasPrescriptor: Array<any> = [];
  tiposPrescriptor: Array<any> = [];
  provincia?: string = '';
  tipo?: string = '';
  numMatricula?: string = '';

  extenderPrescripcion: string = '1';
  copias: string = '1';
  mostrarSelect: boolean = false;
  codigoPrescripcionSeleccionada: string = '';

  formPrescriptor: IFormGroup<PrescriptorRequestModel> =
    this.formBuilder.group<PrescriptorRequestModel>({
      ApellidoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CuitPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      EspecialidadPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      FechaReceta: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombrePrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroMatriculaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.pattern('[0-9]+'),
        Validators.required
      ]),
      ProvinciaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.required
      ]),
      TipoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.required
      ]),
    });

  constructor(
    private store: Store<AppState>,
    private agendaService: AgendaService,
    private modalService: NgbModal,
    private persistenciaService: PersistenciaService,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    this.navigateEvent(this.objFechas.fecha6);
  }

  //TODO 1-SACAR TODO LO DE ABAJO DE DIAGNOSTICO
  //TODO 2-SACAR COPIAS
  //TODO 3-MODIFICAR BTN "AGREGAR MEDICAMENTOS" POR "AGREGAR ESTUDIOS"

  ngAfterViewInit() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  ngOnInit(): void {
    this.listenEnviosPrescripcion();
    this.getData();
    this.cargarDatos();

    this.subscriptionStore = this.store
      .select('datosTransaccion')
      .subscribe((datos) => {
        this.detallesMedicamentos = datos.DetalleMedicamentosRequest;
        this.detallesEstudios = datos.DetalleEstudiosRequest;
        this.extraDispensa = datos.extraDispensaRequest || null;
        // if (datos.BeneficiarioRequest?.Email == null || '') {
        //   this.form.controls['emailBeneficiario'].markAsUntouched();
        //   this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionEmailBeRequest(false));
        // }
      });

    this.subscriptionDatosInicioStore = this.store
      .select('datosInicio')
      .subscribe(async (datos) => {
        this.prestadorSelecionado = datos.PrestadorRequest;
        this.credencialSelecionada = datos.CredencialRequest;
        this.finanaciadorSeleccionado = datos.FinanciadorRequest;

        if (this.credencialSelecionada) {
          let result = await this.agendaService.getContactoPorCredencial('' + this.credencialSelecionada?.NumeroCredencial, '' + this.finanaciadorSeleccionado?.CuitFinanciador).pipe(take(1));
          result.subscribe(
            (res: any) => {
              if (res) {
                this.form.controls['emailBeneficiario'].setValue(res[0].email);
                console.log('email beneficiario response', res[0].email);
              }
            }, err => {
              //Pedir agendar
              console.log('No existe en la agenda', err)
            },
            () => console.log('HTTP request completed.')
          );
        }

        this.codigoPrescripcionSeleccionada = datos.PrescripcionDispensaRequest?.CodigoPrescripcion || '';
      });


    this.firma = this.persistenciaService.getLocal('firmaLocal');
    this.store.dispatch(new setExtraDispensaRequest({ ...this.extraDispensa, firma_olografa: this.firma }));
    this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionEmailBeRequest(this.form.valid));

    this.validarFirma();
  }

  getData() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.tiposPrescriptor =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoMatricula || [];
      this.provinciasPrescriptor =
        datos.datosInicialesExtra.datosInicialesExtra?.provincia || [];
    });

    console.log('tipo Prescriptor', this.tiposPrescriptor);
    console.log('tipo Prescriptor', this.provinciasPrescriptor);
  }

  validarFirma() {

    this.subscriptionStore = this.store.select('datosTransaccion').subscribe((datos) => {
      this.existeFirma = datos.extraDispensaRequest?.firma_olografa!

      if (this.existeFirma) {
        this.store.dispatch(new setEstadoFormFirmaRequest(true));
      } else {
        this.store.dispatch(new setEstadoFormFirmaRequest(false));
      }
    })

  }

  listenEnviosPrescripcion() {
    this.subscriptionFormStatus = this.form.statusChanges.subscribe((status) => {
      this.form.controls['emailBeneficiario'].markAsTouched();
      this.form.controls['dniPaciente'].markAsTouched();

      if (this.form.status === 'VALID') {
        this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionEmailBeRequest(true));
        this.updateExtraDispensa();
      } else {
        this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionEmailBeRequest(false));
      }
    });

    this.subscriptionFormStatus = this.formPrescriptor
      .statusChanges.subscribe((status) => {
        console.log('status prescriptor', status);

        this.formPrescriptor.controls['NroMatriculaPrescriptor'].markAsTouched();
        this.formPrescriptor.controls['TipoPrescriptor'].markAsTouched();
        this.formPrescriptor.controls['ProvinciaPrescriptor'].markAsTouched();
        if (status == 'VALID') {
          this.store.dispatch(new setEstadoFormPrescriptorRequest(true));
          this.updateExtraDispensa();
        } else {
          this.store.dispatch(new setEstadoFormPrescriptorRequest(false));
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionMedicamentosStore.unsubscribe();
    this.subscriptionFormStatus.unsubscribe();
    this.subscriptionDatosInicioStore.unsubscribe();
    //this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionDiagRequest(false));
    //this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionEmailBeRequest(false));
  }

  calcula(tipo: boolean, detalleMed: DetalleMedicamentosRequestModel) {
    let cantidad = +detalleMed.cantidad_prescripta;
    // if(tipo) {
    //   cantidad++;
    // } else {
    //   if(cantidad>1) {
    //     cantidad--;
    //   }
    // }
    let nuevoArray: DetalleMedicamentosRequestModel[] = [];
    let index = this.detallesMedicamentos!.indexOf(detalleMed);
    var i = 0;
    this.detallesMedicamentos!.forEach(item => {
      if (i == index) {
        nuevoArray.push({ ...item, cantidad_prescripta: '' + cantidad });
      } else {
        nuevoArray.push(item);
      }
      i++;

    });
    this.store.dispatch(new setDetallesMedicamentosRequest(nuevoArray));
  }

  abrirModal(longContent: any, detalleMed: DetalleMedicamentosRequestModel) {
    this.store.dispatch(new setMedicamentoSeleccionadoRequest(detalleMed));
    this.modalService.open(longContent, { centered: true, scrollable: true });
  }

  borrarItem(detalleMed: DetalleMedicamentosRequestModel) {
    this.detallesMedicamentos = this.detallesMedicamentos!.filter(array => array.items[0].cod_troquel !== detalleMed.items[0].cod_troquel)
    if (this.detallesMedicamentos.length > 0) {
      this.store.dispatch(new setDetallesMedicamentosRequest(this.detallesMedicamentos));
    } else {
      this.store.dispatch(new unsetDetallesMedicamentosRequest());
      this.store.dispatch(new unsetExtraDispensaRequest());
      this.store.dispatch(new setEstadoFormDetallesMedicamentosRequest(false));
    }
  }

  async navigateEvent(event: any) {
    console.log('event: ', event);
    let hoy = new Date(event.month + '/' + event.day + '/' + event.year);
    await this.sumarFecha(hoy.getTime() + (28 * 24 * 60 * 60 * 1000));
    this.fechaFinAPI = this.objFechas.fecha2 + " " + this.objFechas.hora2;
    this.fechaHoyAPI = event.year + '/' + event.month.toString().padStart(2, 0) + '/' + event.day.toString().padStart(2, 0) + " " + this.objFechas.hora2;
    console.log('fechaFinAPI: ', this.fechaFinAPI + ', fechaHoyAPI: ' + this.fechaHoyAPI);
    this.updateExtraDispensa();
  }

  async sumarFecha(dias: number) {
    this.objFechas.setHoy(dias);
    this.fecha30 = this.objFechas.fecha3;
    console.log("fecha hasta:", this.fecha30);
  }

  async updateExtraDispensa() {
    //Busco la matricula seleccionada para enviar objeto
    //console.log('this.selectMatricula.nativeElement.value)[0]:', this.selectMatricula.nativeElement!.value);
    let datosPrescriptor = this.formPrescriptor.getRawValue();
    // let datos = this.form.getRawValue();
    let matricula_select = {
      provincia: datosPrescriptor.ProvinciaPrescriptor || '',
      tipo: datosPrescriptor.TipoPrescriptor || '',
      numero: datosPrescriptor.NroMatriculaPrescriptor || '',
      descripcion: this.prestadorSelecionado?.matriculas?.[0]?.descripcion || '',
    }

    let extenderPrescripcionValue = this.mostrarSelect ? this.extenderPrescripcion : '0'
    let copiasValue = this.copias
    let dniPaciente = this.form.get('dniPaciente')?.getRawValue();

    let extDispensa: ExtrasDispensa = {
      ...this.extraDispensa,
      estado: 'S',
      fecha_receta: this.fechaHoyAPI,
      fecha_dispensa_fin: this.fechaFinAPI,
      multi_dispensa: this.checkboxMultidispensa ? (this.checkboxMultidispensa.nativeElement.checked ? '1' : '0') : '0',
      prescriptor_copia: this.checkboxEnviaCopia ? (this.checkboxEnviaCopia.nativeElement.checked ? '1' : '0') : '0',
      justifica: this.checkboxJustifica ? (this.checkboxJustifica.nativeElement.checked ? '1' : '0') : '0',
      tratamiento_prolongado: this.checkboxProlongado ? (this.checkboxProlongado.nativeElement.checked ? '1' : '0') : '0',
      matricula: matricula_select,
      beneficiario_email: this.form.controls['emailBeneficiario'].valid ? this.form.controls['emailBeneficiario'].value : '',
      beneficiario_dni: dniPaciente,
      periodo_autorizado: extenderPrescripcionValue,
      numero_copia: copiasValue,
    };
    this.store.dispatch(new setExtraDispensaRequest(extDispensa));
  }

  firmar(firmaContent: any) {
    this.modalService.open(firmaContent, { centered: true, size: 'lg', scrollable: true });
  }

  agregarMed(agregarMedContent: any) {
    if (this.detallesMedicamentos!.length < 3) {
      this.modalService.open(agregarMedContent, { centered: true, size: 'lg', scrollable: true });
    }
  }

  agregarEstudios(agregarEstudioContent: any) {
    this.modalService.open(agregarEstudioContent, { centered: true, size: 'lg', scrollable: true });
  }

  cargarDatos() {
    let tipo = this.tiposPrescriptor.find(
      (x) => x.nombre == 'Médico'
    );
    this.formPrescriptor.controls['TipoPrescriptor'].setValue(tipo?.codigo || null);

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.provincia = datos.datosInicio.PrestadorRequest?.matriculas![0].provincia
    });
    this.formPrescriptor.controls['ProvinciaPrescriptor'].setValue(this.provincia || null);

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.numMatricula = datos.datosInicio.PrestadorRequest?.matriculas?.[0].numero;
    });
    this.formPrescriptor.controls['NroMatriculaPrescriptor'].setValue(this.numMatricula || null);
  }

  abrirModalEstudio(editEstudioContent: any, detalleEst: DetalleEstudiosRequestModel) {
    this.store.dispatch(new setEstudioSeleccionadoRequest(detalleEst));
    this.modalService.open(editEstudioContent, { centered: true, scrollable: true });
  }

  borrarEstudio(detalleEst: DetalleEstudiosRequestModel) {
    this.detallesEstudios = this.detallesEstudios!.filter(estudio => estudio.nbu !== detalleEst.nbu);
    if (this.detallesEstudios.length > 0) {
      this.store.dispatch(new setDetallesEstudiosRequest(this.detallesEstudios));
    } else {
      this.store.dispatch(new unsetDetallesEstudiosRequest());
      this.store.dispatch(new setEstadoFormDetallesMedicamentosRequest(false));
    }
  }

  renovarToken() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .pipe(take(1))
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.authService.esTokenValido().subscribe(
            (respuesta) => {
              if (respuesta.token) {
                this.store.dispatch(new setNuevoToken(respuesta.token));
              }
            },
            (error: HttpErrorResponse) => {
              this.globalService.manageError(error);
            }
          );
        }
      });
  }

}

<!-- <div class="fondo">
    <div class="container-fluid fondo pt-3">
        <div>
            <div class="row mt-3">
                <div class="col-4"></div>
                <div class="col-4 text-center"><img src="./assets/img/logo.svg" style="height: 70px;"></div>
                <div class="col-4"></div>
            </div>
            <div class="row mt-5 justify-content-center">
                <div class="col-9 bg-login mt-3 rounded shadow">
                    <h3 class="p-4">{{mensaje}}</h3>
                    <form [formGroup]="form" class="m-4" (ngSubmit)="enviarInformacion()" action="aplicaciones">
                        <div class="form-group">
                            <div class="form-row mt-3">
                                <label>Ingrese su cuit <span class="text-danger">*</span></label>
                                <input type="text" maxlength="11" name="cuit" placeholder="CUIT" class="form-control" autocomplete="off" formControlName="cuit">

                                <small *ngIf="submitted && !form.controls['cuit'].errors?.required" class="text-danger animated fadeIn form-text">Debe ingresar 11
                  caracteres numéricos sin guiones</small>
                            </div>

                            <div class="form-row mt-3">
                                <label>Ingrese el lugar de atención <span class="text-danger">*</span></label>
                                <input type="text" name="direccion" placeholder="Dirección del lugar de atención" class="form-control" autocomplete="off" formControlName="direccion">
                            </div>

                            <div class="form-row mt-3">
                                <label>Describa su problema<span class="text-danger">*</span></label>
                                <textarea class="form-control" class="form-control" formControlName="contexto" rows="3"></textarea>
                            </div>

                            <div class="form-row mt-3">
                                <label>Ingrese un teléfono al que lo podamos contactar<span class="text-danger">*</span></label>
                                <input type="text" name="telefono" placeholder="Teléfono" class="form-control" autocomplete="off" formControlName="telefono" maxlength="10">
                                <small *ngIf="submitted && form.controls['telefono'].errors?.pattern" class="text-danger animated fadeIn form-text">Ingrese un número de telefono/celular
                  válido</small>
                            </div>

                            <div class="form-row mt-3">
                                <label>Ingrese un email al que lo podamos contactar<span class="text-danger">*</span></label>
                                <input type="text" name="email" placeholder="Email" class="form-control" autocomplete="off" formControlName="email">
                                <small *ngIf="submitted && form.controls['email'].errors?.email" class="text-danger animated fadeIn form-text">Debe ingresar un formato de email
                  valido</small>
                            </div>

                        </div>

                        <div class="form-group row mt-5">
                            <div class="col-6">
                                <p><small class="text-danger">* Campos son obligatorios</small></p>
                            </div>
                            <div class="input-group col-6 justify-content-end">
                                <button type="submit" class="btn btn-pcpos mr-3" (click)="volver()">
                  Volver
                </button>
                                <button type="submit" class="btn btn-pcpos" (click)="enviarInformacion()">
                  Enviar
                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->




<!-- <div class="fondo">
    <div class="container-fluid fondo pt-3">
        <div>
            <div class="row mt-3">
                <div class="col-4"></div>
                <div class="col-4 text-center"><img src="./assets/img/logo.svg" style="height: 70px;"></div>
                <div class="col-4"></div>
            </div>
            <div class="row mt-5 justify-content-center">
                <div class="col-9 bg-login mt-3 rounded shadow">
                    <h3 class="p-4">Se produjo un error</h3>

                    <div class="form-row mt-3">
                        <label>Se puede comunicar con nosotros a través de los siguientes medios: </label>
                    </div>
                    <div class="form-row mt-3">
                        <p>E-mail: soporte@itcsoluciones.com</p>
                    </div>
                    <div class="form-row mt-3">
                        <p>WhatsApp:&nbsp;</p>
                        <p class="link" (click)="abrirWhatsapp()"> +54 9 261 754-3008</p>
                    </div>
                    <div class="form-row mt-3">
                        <p>Teléfono: 0810-08102-482</p>
                    </div>

                    <div class="form-group row mt-5">

                        <div class="input-group col-6 justify-content-end">
                            <button type="submit" class="btn btn-pcpos mr-3" (click)="volver()">
                        Volver
                      </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div> -->

<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Nuvalid</title>
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>



<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="globalService.ocultarModal">

    <div class="containerN ">

        <main class="cardN-modal" id="modal-contact">
            <div class="card__columnL card__columnL--modal" id="column__modalInstalacion">
                <figure class="card__logoNuvalidC">
                    <img src="./assets/design/logoNuvalid.svg" alt="Nuvalid Logo" class="card__logoNuvalid">
                    <!-- <div id="logoN"> </div> -->
                </figure>
                <figure class="card__phoneC">
                    <!-- <a href="#" id="phone"></a> -->
                    <!-- <a href="#"><img src="/assets/phone.svg" alt="phoneNuvalid" class="card__phone"></a> -->
                </figure>
            </div>
            <div class="card__columnR " id="card__columnR--modal">
                <!--agregue estilo ver Diana-->
                <form>
                    <div>
                        <div class="form-errorN form-data__contact">

                            <figure>

                                <a class="pointer" (click)="cerrarModal()"><img src="./assets/design/close.svg" alt="Nuvalid Botón Cerrar" class="form-contact__image"></a>
                            </figure>
                        </div>
                        <div class="form-infoN form-info--modal">
                            <p class="form-info__text">{{mensaje}}<br><b>{{error}}</b></p>
                        </div>
                        <div class="form-dataN">

                            <div>
                                <div class="form-contactN">
                                    <figure>
                                        <img src="./assets/design/time.svg" alt="Nuvalid Ícono Hora">
                                    </figure>
                                    <p class="form-contact__text">Lunes a Viernes de 8 a 23 hs. Sábados de 9 a 17 hs.</p>
                                </div>
                                <div class="form-contactN">
                                    <figure>
                                        <img src="./assets/design/email.svg" alt="Nuvalid Ícono Email">
                                    </figure>
                                    <a href="mailto:soporte@itcsoluciones.com" class="form-contact__link">soporte@itcsoluciones.com</a>
                                </div>

                                <div class="form-contactN">
                                    <figure>
                                        <img src="./assets/design/whatsapp.svg" alt="Nuvalid Ícono Whatsapp">
                                    </figure>
                                    <a (click)="enviarWhatsapp()" class="form-contact__link">+54 9 261 754-3008</a>
                                </div>
                                <div class="form-contactN">
                                    <figure>
                                        <img src="./assets/design/telefono.svg" alt="Nuvalid Ícono Celular">
                                    </figure>
                                    <p class="form-contact__text">0810 - 8102 - 482</p>
                                </div>
                            </div>
                            <div class="components">
                                <figure>
                                    <img src="./assets/design/contacto.svg" alt="Nuvalid Ilustración Mujer" id="ilustrations-contactWoman">
                                </figure>
                            </div>
                        </div>
                    </div>
                </form>


            </div>

        </main>

    </div>


</div>

</html>

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TransaccionRequestModel } from '@models/datosInicio.models';
import { PrescripcionRequestModel } from '@models/datosTransaccion.models';
import { Receta } from '@models/rescateResponse.model';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { TransaccionesService } from '@services/transacciones.service';
import { ActivarLoading, DesactivarLoading, setPrescripcionRequest, setTransaccionRequest } from '@store/actions';
import { setEsRecetaRequest, setRecetasRequest } from '@store/actions/recetas.action';
import { AppState } from '@store/app.reducers';
import { datosInicioState } from '@store/models/store.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-rescate-receta',
  templateUrl: './datos-rescate-receta.component.html',
  styleUrls: ['./datos-rescate-receta.component.scss']
})
export class DatosRescateRecetaComponent implements OnInit {

  private subscriptionStore: Subscription = new Subscription();

  datosInicio: datosInicioState | null = null;
  hasRecipes: boolean = false;
  recipes: Array<Receta> | null = null;
  searchPerformed: boolean = false;
  selectedRecipe: Receta | null = null;
  informarPrestacion: boolean = false;
  bioquimico: boolean = false;
  codigoTransaccion: any;

  constructor(
    private store: Store<AppState>,
    private transaccionesService: TransaccionesService,
    private dialogsService: DialogsService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.datosInicio = datos.datosInicio;
      this.codigoTransaccion = datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;

      if (datos.datosInicio.PrestadorRequest?.TipoPrestador === 'Bioquimica') {
        this.bioquimico = true;
      }
    });

    this.getRecipes();
  }

  async getRecipes() {
    try {
      this.store.dispatch(new ActivarLoading());
      this.dialogsService.cargando('Buscando recetas, por favor espere.');
      // Realiza la llamada al servicio para obtener todas las recetas
      let respuesta = await this.txRescateRecetas();

      if (respuesta) {
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
        this.hasRecipes = true;
        this.recipes = respuesta.Mensaje.Recetas.Receta;
        this.searchPerformed = true;
        this._changeDetectorRef.markForCheck();
      } else {
        this.hasRecipes = false;
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.dialogsService.error(error.error.status, error.error.message);
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
      } else {
        this.dialogsService.error('Error inesperado', 'No se pudieron rescatar recetas');
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
      }
    }
  }

  txRescateRecetas() {
    return this.transaccionesService.rescatarRecetas(this.datosInicio!)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        this.dialogsService.error(error.error.status, error.error.message)
      });
  }

  formatDate(fecha: string): string {
    return fecha.split(' ')[0];
  }

  selectRecipe(recipe: Receta) {
    this.selectedRecipe = recipe;
    console.log('selectedRecipe', this.selectedRecipe);
    this.store.dispatch(new setRecetasRequest(this.selectedRecipe));
    this.store.dispatch(new setEsRecetaRequest(true));

    if (this.bioquimico) {
      const opInfoPresta = new TransaccionRequestModel('Informar prestación preautorizada', '02L');
      this.store.dispatch(new setTransaccionRequest(opInfoPresta));
    } else {
      const opInfoPresta = new TransaccionRequestModel('Informar prestación', '02A');
      this.store.dispatch(new setTransaccionRequest(opInfoPresta));
    }

    console.log('this.selectedRecipe.Id', this.selectedRecipe.Id);
    if (this.selectedRecipe.Id) {
      let prescripcionRequest = new PrescripcionRequestModel(
        '',
        this.selectedRecipe.Id,
        ''
      );
      this.store.dispatch(new setPrescripcionRequest(prescripcionRequest));
    }

  }
}

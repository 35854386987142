<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<ng-template #longContent let-modal>
  <app-datos-indicaciones></app-datos-indicaciones>
</ng-template>
<ng-template #firmaContent let-modal>
  <app-datos-firma-manuscrita></app-datos-firma-manuscrita>
</ng-template>
<ng-template #agregarMedContent let-modal>
  <app-input-monodroga></app-input-monodroga>
</ng-template>
<ng-template #agregarEstudioContent let-modal>
  <app-input-estudio></app-input-estudio>
</ng-template>
<!-- <ng-template #agregarMedContent let-modal>
  <app-card-prescripcion></app-card-prescripcion>
</ng-template> -->

<div class="container__medicamentos" (click)="renovarToken()">
  <!--------------START COLUMNA IZQUIERDA---------------->

  <div class="medicamentos__columnL">
    <p class="title__dataPrescripcion">Fecha de validez</p>
    <div class="containerInput__fecha">
      <div class="form-infoN-fechaM">
        <label for="fDesde" class="label__fecha">Fecha</label>
        <form>
          <input id="fDesde" class="form-controlN-fechaM fechaDesde" placeholder="{{valuedate | date: 'dd/MM/yyyy'}}"
            name="d2" #c2="ngModel" [(ngModel)]="model" ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
            (dateSelect)="navigateEvent($event)">
          <button class="editPrescripcion buttonMedicament" type="button" ngbTooltip="Elegir fecha"
            tooltipClass="my-custom-class" (click)="d2.toggle();" type="button"><img src="./assets/design/calendar.svg"
              alt="Nuvalid Ilustración Calendario"></button>
        </form>
        <!-- <label for="fHasta" class="label__fecha">Hasta</label>
        <form>
          <input class="form-controlN-fechaM" id="fHasta" readonly [value]="fecha30">
        </form> -->
      </div>
    </div>

    <div class="optionsDiagnostico">

      <!-------------DIAGNOSTICOS------------->
      <app-datos-diagnostico-disp-request></app-datos-diagnostico-disp-request>
      <ng-container *ngIf="codigoPrescripcionSeleccionada != '5'">
        <form action="get">
          <div class="container__optionMedicamento">
            <input type="checkbox" value="" id="option1" #checkboxMultidispensa (change)="updateExtraDispensa()">
            <label class="labelOptionMedicamentos" for="option1">
              Realizar una prescripción separada para cada medicamento.
            </label>
          </div>
          <!-- <div class="container__optionMedicamento">
            <input type="checkbox" id="option2" #checkboxJustifica (change)="updateExtraDispensa()">
            <label class="labelOptionMedicamentos justif" for="option2">
              Justifico el uso de marca comercial por conocimiento de eficacia y seguridad
            </label>
          </div> -->
          <div class="container__optionMedicamento">
            <input type="checkbox" id="option3" #checkboxProlongado (change)="updateExtraDispensa()">
            <label class="labelOptionMedicamentos justif" for="option3">
              Tratamiento prolongado
            </label>
          </div>
        </form>
        <div class="form-infoN">
          <input type="checkbox" [(ngModel)]="mostrarSelect" (change)="updateExtraDispensa()">
          <label class="labelOptionMedicamentos">Extender prescripción</label>
          <ng-container *ngIf="mostrarSelect">
            <select class="form-controlN" style="width: 15%;" [(ngModel)]="extenderPrescripcion"
              name="extenderPrescripcion" (change)="updateExtraDispensa()">
              <option [ngValue]="'1'">1 mes</option>
              <option [ngValue]="'2'">2 meses</option>
              <option [ngValue]="'3'">3 meses</option>
              <option [ngValue]="'4'">4 meses</option>
              <option [ngValue]="'5'">5 meses</option>
              <option [ngValue]="'6'">6 meses</option>
            </select>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <form action="get" [formGroup]="form" class="form__beneficiario">
        <div>
          <label for="dniPaciente" class="label__beneficiarioEmail">DNI del paciente <span
              class="span-required">*</span>
          </label>
          <input type="text" name="dniPaciente" id="emailBeneficiario" tooltipClass="my-custom-class"
            formControlName="dniPaciente" placeholder="Ingresar DNI del paciente" (change)="updateExtraDispensa()">
          <small class="text-danger animated fadeIn mt-0"
            *ngIf="form.get('dniPaciente')?.errors && form.get('dniPaciente')?.touched"><br>Por
            favor ingrese un DNI válido</small>
        </div>
      </form>
    </div>

    <!-----------------EMAIL Y CHECK DE COPIA AL PROFESIONAL------------------->
    <div class="container__beneficiario">
      <form action="get" [formGroup]="form" class="form__beneficiario">
        <p>Se enviará la receta al siguiente correo:</p>
        <div>
          <label for="emailBeneficiario" class="label__beneficiarioEmail">Email afiliado <span
              class="span-required">*</span> </label>
          <input type="text" name="emailBeneficiario" id="emailBeneficiario" placement="top"
            ngbTooltip="Si desea modificar un email por defecto, el mismo se realiza desde la agenda de pacientes."
            tooltipClass="my-custom-class" formControlName="emailBeneficiario" placeholder="Ingresar email afiliado">
          <small class="text-danger animated fadeIn mt-0"
            *ngIf="form.get('emailBeneficiario')?.errors && form.get('emailBeneficiario')?.touched"><br>Por
            favor ingrese un email válido</small>
        </div>
        <div class="container__radioBeneficiario">
          <input type="checkbox" value="" id="copiaMedicamento" #checkboxEnviaCopia (change)="updateExtraDispensa()">
          <label class="labelOptionMedicamentos" for="copiaMedicamento">
            Enviar una copia al profesional
          </label>
        </div>
      </form>
      <ng-container *ngIf="codigoPrescripcionSeleccionada != '5'">
        <div class="form-infoN mt-3">
          <label class="labelOptionMedicamentos">Copias</label>
          <select class="form-controlN" style="width: 15%;" [(ngModel)]="copias" name="copias"
            (change)="updateExtraDispensa()">
            <option [ngValue]="'1'">1</option>
            <option [ngValue]="'2'">2</option>
            <option [ngValue]="'3'">3</option>
          </select>
        </div>
      </ng-container>
      <!------------------IMG DOCMEDICAMENTO--------------------->
      <figure>
        <img src="./assets/design/medicamentoD.svg" alt="Nuvalid Ilustración Medicamento" id="docMedicamento">
      </figure>
    </div>
  </div>
  <!---------------------END COLUMNA IZQUIERDA--------------------->

  <!------------START COLUMNA DERECHA--------------->

  <div class="medicamentos__columnR">

    <!------------------DATOS DEL PROFESIONAL--------------------->
    <div class="row" style="margin-bottom: 20px;">
      <div class="col">
        <ng-container *ngIf="codigoPrescripcionSeleccionada == '5'; else medicamentos">
          <p class="title__datosProfesional">Prescripción</p>
        </ng-container>
        <ng-template #medicamentos>
          <p class="title__datosProfesional">Receta</p>
        </ng-template>
        <hr class="mt-0 mb-0">
        <p class="data__datosProfesional">Nombre: <cite
            title="Source Title">{{prestadorSelecionado?.RazonSocial}}</cite></p>
        <div class="col form-infoN" style="display: flex; gap: 25px;">
          <form [formGroup]="formPrescriptor" class="col form-infoN" style="display: flex; gap: 15px;">
            <div class="form-infoN">
              <label for="matriculaPrescriptor">Matrícula <span class="span-required">*</span></label>
              <select class="form-controlN" formControlName="NroMatriculaPrescriptor" id="matriculaPrescriptor">
                <option *ngFor="let matricula of matriculasPrescriptor" [value]="matricula.matricula">
                  {{ matricula.matricula }}
                </option>
              </select>

              <label for="tipoPrescriptor">Tipo <span class="span-required">*</span></label>
              <input type="text" class="form-controlN" formControlName="TipoPrescriptor" id="tipoPrescriptor"
                [disabled]="true">

              <label for="provinciaPrescriptor">Provincia <span class="span-required">*</span></label>
              <select class="form-controlN" formControlName="ProvinciaPrescriptor" id="provinciaPrescriptor"
                [disabled]="true">
                <option [ngValue]="provinciaPrescriptor.codigo"
                  *ngFor="let provinciaPrescriptor of provinciasPrescriptor">
                  {{ provinciaPrescriptor.nombre }}
                </option>
              </select>
            </div>
            <div class="containerInfo">
              <span data-bs-toggle="tooltip" data-bs-placement="top"
                title="Si la matrícula no es la correcta, comuníquese con soporte">
                <fa-icon [icon]='["fas", "info-circle"]' style="font-size: 1.5rem; color: #4459A3;"></fa-icon>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-----------RECETA CANTIDAD----------->
    <ng-container *ngIf="codigoPrescripcionSeleccionada == '5'; else medicamentos">
      <ng-container *ngIf="detallesEstudios!.length < 3">
        <div class="agregarMedicamentos">
          <button class="buttonImprimirTickets" style="width: auto;" type="button"
            (click)="agregarEstudios(agregarEstudioContent)" [disabled]="agregarMedicamentoDeshabilitado">Agregar
            estudios</button>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #medicamentos>
      <ng-container *ngIf="detallesMedicamentos!.length < 3">
        <div class="agregarMedicamentos">
          <button class="buttonImprimirTickets" style="width: auto;" type="button"
            (click)="agregarMed(agregarMedContent)" [disabled]="agregarMedicamentoDeshabilitado">Agregar
            medicamentos</button>
        </div>
      </ng-container>
    </ng-template>

    <div class="container__dataPrescripcion">
      <p class="title__dataPrescripcion">Rp/</p>

      <ng-container *ngIf="codigoPrescripcionSeleccionada == '5'; else opMedicamento">

        <ol>
          <li class="dataPrescripcion" *ngFor="let detalleEst of detallesEstudios">
            <div class="textPrescripcion">
              <p class="titlePrescripcion">{{detalleEst.determinacion}}</p>
            </div>
            <div class="accionPrescripcion">
              <form action="" [formGroup]="formul">
                <input type="number" min="1" max="99" maxlength="2" formControlName="ctdad"
                  class="form-control text-center" [value]="1">
              </form>
              <button class="editPrescripcion buttonMedicament" type="button" ngbTooltip="Agregar indicación"
                tooltipClass="my-custom-class" (click)="abrirModalEstudio(longContent,detalleEst)"><img
                  src="./assets/design/editMedicament.svg" alt="Nuvalid Botón Editar"></button>
              <button class="deletePrescripcion buttonMedicament" type="button" ngbTooltip="Eliminar medicamento"
                (click)="borrarEstudio(detalleEst)"><img src="./assets/design/deletMedicament.svg"
                  alt="Nuvalid Botón Eliminar"></button>
            </div>
          </li>
        </ol>
      </ng-container>

      <ng-template #opMedicamento>
        <ol>
          <li class="dataPrescripcion" *ngFor="let detalleMed of detallesMedicamentos">
            <div class="textPrescripcion">
              <p class="titlePrescripcion">{{detalleMed.droga_descripcion}}</p>
              <ng-container *ngIf="detalleMed.items[0].descripcion; else sinDescripcion">
                <p class="descriptionPrescripcion">{{detalleMed.items[0].descripcion}}
                  {{detalleMed.generico_presentacion}}</p>
              </ng-container>
              <ng-template #sinDescripcion>
                <p class="descriptionPrescripcion">{{detalleMed.generico_presentacion}}</p>
              </ng-template>
            </div>
            <div class="accionPrescripcion">
              <form action="" [formGroup]="formul">
                <input type="number" min="1" max="99" maxlength="2" formControlName="ctdad"
                  class="form-control text-center" [value]="this.formul.controls['ctdad'].getRawValue()"
                  (ngModelChange)="calcula(detalleMed, this.formul.controls['ctdad'].getRawValue())">
              </form>
              <button class="editPrescripcion buttonMedicament" type="button" ngbTooltip="Agregar indicación"
                tooltipClass="my-custom-class" (click)="abrirModal(longContent,detalleMed)">
                <img src="./assets/design/editMedicament.svg" alt="Nuvalid Botón Editar">
              </button>
              <button class="deletePrescripcion buttonMedicament" type="button" ngbTooltip="Eliminar medicamento"
                (click)="borrarItem(detalleMed)">
                <img src="./assets/design/deletMedicament.svg" alt="Nuvalid Botón Eliminar">
              </button>
            </div>

          </li>
        </ol>
      </ng-template>


      <!-----------INFO PRESCRIPCION MEDICAMENTO----------->

      <div class="infoPrescripcion">
        <p>{{extraDispensa?.diagnostico}}</p>
        <!-- REVISAR CUAL HAY QUE PONER ESTA DUPLICADA LA INFORMACION en las columnas -->
        <p *ngIf="extraDispensa?.justifica == '1'">Justifico el uso de marca comercial por conocimiento de eficacia y
          seguridad.</p>
      </div>

      <!-----------FIRMA----------->

      <div class="container__firma">
        <figure>
          <img *ngIf="extraDispensa?.firma_olografa" [src]="extraDispensa?.firma_olografa"
            src="./assets/design/firma.svg" alt="Nuvalid Firma">
        </figure>
        <button class="buttonImprimirTickets" style="width: 130px;" type="button"
          (click)="firmar(firmaContent)">Firmar</button>

      </div>
    </div>
  </div>

  <!------------END COLUMAN DERECHA--------------->

</div>

</html>

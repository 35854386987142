<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>


<div class="container__panelPrestacion">
    <div class="container-informar-diagnostico">

        <!-- Nro de ticket -->
        <app-datos-anulacion-request></app-datos-anulacion-request>

        <!-- Diagnostico -->
        <app-datos-diagnostico-disp-request class="form__informar-diagnostico" [nomenclador]="true">
        </app-datos-diagnostico-disp-request>

        <!-- Imagen -->
        <div class="container__infoDiagOneRow">
            <figure class="container__ilustrationInfoDiag">
                <img src="./assets/design/doctoraInfoDiag.svg" alt="Nuvalid Ilustración Médico">
            </figure>
        </div>

    </div>

</div>


</html>

<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>

<!-- START DESPLEGABLE NUVALID -->

<app-desplegable [componentePasado]=inicio titulo="  INICIALES" identificador="inicio" [required]=true
  [showEvent]=!ocultarDatosIniciales #datosIniciales>
</app-desplegable>

<ng-container *ngIf="mostrarOperaciones">
  <app-desplegable [componentePasado]=operaciones titulo="Seleccionar operación" identificador="operaciones"
    [required]=true [showEvent]=!ocultarOperaciones>
  </app-desplegable>
</ng-container>

<ng-container *ngIf="mostrarPrescripciones">
  <div>
    <app-desplegable [componentePasado]=prescrip titulo="Realizar prescripción" identificador="prescrip" [required]=true
      [showEvent]=!ocultarPrescripciones>
    </app-desplegable>
  </div>
</ng-container>

<!-- END DESPLEGABLE NUVALID -->

<ng-container *ngIf="mostrarOperaciones && codigoTransaccionSeleccionada && !mostrarPrescripciones">
  <app-informar-transaccion></app-informar-transaccion>
</ng-container>


<ng-container *ngIf="mostrarPrescripciones && codigoPrescripcionSeleccionada && (datosTransaccion?.DetalleMedicamentosRequest || datosTransaccion?.DetalleEstudiosRequest)">
  <ng-container *ngIf="codigoPrescripcionSeleccionada == '1' || codigoPrescripcionSeleccionada == '5'">
    <div class="container__medicamentos">
      <app-prescripcion-medicamento class="app-prescripcion-medicamento"></app-prescripcion-medicamento>
    </div>
  </ng-container>
</ng-container>



<div class="container__buton">
  <div class="containerClean">
    <p>
      <button *ngIf="!cargando" type="submit" class="butonClean" data-title="Se borrará todo
        lo cargado" (click)="limpiarDatos()">Limpiar datos
      </button>
      <button *ngIf="cargando" type="submit" class="butonClean" [disabled]="true">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>Espere...
      </button>
    </p>
  </div>
  <ng-container *ngIf="(mostrarCodigoSeguridad && (codigoTransaccionSeleccionada === '02A' || (siglaSwiss && codigoTransaccionSeleccionada === '02D'))); else sinCodigoDeSeguridad">
    <button *ngIf="!cargando" type="submit" class="butonTransaction" (click)="informarPrestacionCsc()">Realizar transacción</button>
  </ng-container>
  <ng-template #sinCodigoDeSeguridad>
    <button *ngIf="!cargando" type="submit" class="butonTransaction" (click)="informarPrestacion()">Realizar transacción</button>
  </ng-template>
  <button *ngIf="cargando" type="submit" class="butonTransaction" [disabled]="true">
    <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>Espere...</button>
</div>


<ng-template #inicio>
  <div class="container__panel1">
    <!-- ----FINANCIADORES:seleccion de financiadores----- -->
    <div class="container__financiador">
      <app-card-socios></app-card-socios>
    </div>
    <div class="container__afiliado">
      <app-afiliado></app-afiliado>
      <app-input-agenda (selectAfiliadoAgenda)="selectAfiliadoAgenda($event)"></app-input-agenda>
    </div>
    <div class="container__prestador">
      <app-prestador></app-prestador>
    </div>
  </div>
</ng-template>

<ng-template #operaciones>
  <div class="container__panel2">
    <div class="container__operacionGeneral">
      <div class="container__operacionesOneRow">
        <div class="container__dataOperaciones">
          <div class="operacion operacionNormal" *ngFor="let transaccion of transacciones">
            <app-card-transaccion [transaccion]="transaccion"></app-card-transaccion>
          </div>

          <div class="operacion operacionMore" (click)="agregarOperacion()">
            <img src="./assets/design/more.svg" alt="Nuvalid Ícono Más">
            <p class="operacion__text">Ver más operaciones</p>
          </div>
        </div>
      </div>
      <div class="container__operacionesOneRow">
        <figure class="container__ilustrationOperation">
          <img src="./assets/design/docOperaciones.svg" alt="Nuvalid Ilustración Doctor">
        </figure>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #prescrip>
  <div style="display: flex;">
    <!--  class="row h-50" -->
    <!-- class="col-sm-12 col-md-4 col-lg-3" -->
    <div *ngFor="let prescripcion of prescripciones">
      <app-card-prescripcion [prescripcion]="prescripcion"></app-card-prescripcion>
    </div>
    <figure class="illustrations__medicamentos">
      <img src="./assets/design/medicamentos.svg" alt="Nuvalid Ilustración Medicamentos">
    </figure>
  </div>

</ng-template>

</html>

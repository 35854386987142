import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { setDetallesMedicamentosRequest, unsetMedicamentoSeleccionadoRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { DetalleMedicamentosRequestModel, Item } from '../../../../models/datosTransaccion.models';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';

@Component({
  selector: 'app-datos-indicaciones',
  templateUrl: './datos-indicaciones.component.html',
  styleUrls: ['./datos-indicaciones.component.scss']
})
export class DatosIndicacionesComponent implements OnInit {
  subscriptionStore: Subscription = new Subscription();
  subscriptionStoreTransac: Subscription = new Subscription();
  medicamento: DetalleMedicamentosRequestModel | null = null;
  item: Item | undefined;
  precaucion: string = '';
  indicacion: string = '';
  detallesMedicamentos: Array<DetalleMedicamentosRequestModel> | any = null;

    formBuilder: IFormBuilder = new UntypedFormBuilder();

    form: IFormGroup<any> =
      this.formBuilder.group<any>({
        indicacion: new UntypedFormControl(<ControlState<string>>null, [
          blankSpaceValidator,
        ]),
        precaucion: new UntypedFormControl(<ControlState<string>>null, [
          blankSpaceValidator,
        ]),
      });


  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store
      .select('datosDispensa')
      .subscribe(datos => {
        this.medicamento = datos.medicamentoSeleccionadoRequest || null;
        this.item = datos.medicamentoSeleccionadoRequest?.items[0] || undefined;
        this.precaucion = datos.medicamentoSeleccionadoRequest?.precauciones || '';
        this.indicacion = datos.medicamentoSeleccionadoRequest?.posologia || '';
      });

    this.subscriptionStoreTransac = this.store
      .select('datosTransaccion')
      .subscribe(datos => {
        this.detallesMedicamentos = datos.DetalleMedicamentosRequest || [];
      });
  }

  closeModal() {

    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreTransac.unsubscribe();
    this.store.dispatch(new unsetMedicamentoSeleccionadoRequest());
  }

  grabarIndicaciones() {
    let nuevoArray: DetalleMedicamentosRequestModel[] = [];

    let index = this.detallesMedicamentos!.indexOf(this.medicamento);
    var i = 0;

    this.precaucion = this.form.controls['precaucion'].getRawValue();
    this.indicacion = this.form.controls['indicacion'].getRawValue();

    this.detallesMedicamentos!.forEach((item: DetalleMedicamentosRequestModel) => {
      if (i == index) {
        nuevoArray.push({ ...item, precauciones: this.precaucion, posologia: this.indicacion });
      } else {
        nuevoArray.push(item);
      }
      i++;
    });

    this.store.dispatch(new setDetallesMedicamentosRequest(nuevoArray));
    this.closeModal();
  }
}

<div id="downloadModal">
  <div class="" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="downloadModalLabel">Nuevas Funcionalidades para OSDE en NUVALID!</h5>
      </div>
      <div class="modal-body" style="display: flex;">
        <div>
          <!-- Estamos emocionados de anunciar que hemos añadido dos importantes funciones en NUVALID: -->
          <p class="form-info__text">
            Estamos emocionados de anunciar que hemos añadido una función importante en NUVALID:
          </p>
          <p><strong>Prescripción de Estudios:</strong> Ahora puedes prescribir estudios para OSDE directamente desde la
            aplicación.</p>
          <!-- <p><strong>Rescate de Estudios:</strong> Accede y rescata estudios realizados para OSDE de manera fácil y
            rápida.</p> -->
          <!-- <p class="form-info__text">
            Para utilizar estas nuevas funciones, simplemente dirígete a Operaciones y agrégalas.
          </p>
          <p class="form-info__text">
            Estas mejoras están diseñadas para optimizar tu experiencia y agilizar los procesos. ¡Explora estas nuevas
            funciones hoy mismo!
          </p> -->
        </div>
      </div>
      <div class="modal-footer">
        <button class="buttonNoVolverAMostrar" (click)="noVolverAMostrar()">No volver a mostrar</button>
        <button class="buttonAnularTicket" (click)="devolverRespuesta('CERRAR')">Cerrar</button>
      </div>
    </div>
  </div>
</div>

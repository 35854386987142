<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->

</head>
<div class="container__panel1">
  <div class="container__financiador">
    <div class="title_containerF">
      <p class="tittle__dashboard tittle__financiers">Por favor, seleccione el financiador <span
          class="span-required">*</span></p>
    </div>
    <div class="containerFinanciers-img">
      <ng-container
        *ngFor="let financiador of financiadores | filterFinanciadorEnable:prestadores:cuitFinanciadorSeleccionado; let i=index;">
        <ng-container *ngIf="financiador.mostrar; then financiadorEnable else financiadorDisable">
        </ng-container>

        <ng-template #financiadorEnable>
          <figure class="financier" (click)="cargarPrestadores(financiador)"
            [ngClass]=" {'financierSelected' : financiador.cuit === cuitFinanciadorSeleccionado, '': financiador.cuit !== cuitFinanciadorSeleccionado } ">
            <img src="../../../../assets/design/logo_{{ financiador.sigla }}.svg" title="{{financiador.sigla}}"
              alt="Nuvalid {{financiador.sigla}}">
            <!-- <img src="./assets/design/checked.svg" alt="checked"
                            [ngClass]=" {'financier-checked' : financiador.cuit === cuitFinanciadorSeleccionado, 'financier-notChecked': financiador.cuit !== cuitFinanciadorSeleccionado } "> -->
          </figure>
        </ng-template>
        <ng-template #financiadorDisable>
          <figure class="financier" style="opacity: 0.3;">
            <img src="./assets/design/logo_{{financiador.sigla}}.svg" title="{{financiador.sigla}}"
              alt="Nuvalid {{financiador.sigla}}">
          </figure>
        </ng-template>
      </ng-container>
      <!-- <div class="row">
            <ng-container *ngFor="let financiador of financiadores | filterFinanciadorEnable:prestadores:cuitFinanciadorSeleccionado; let i=index;">
                <div class="col-sm-6 col-lg-4 col-xl-3">
                    <ng-container *ngIf="financiador.mostrar; then financiadorEnable else financiadorDisable">
                    </ng-container>

                    <ng-template #financiadorEnable>
                        <div class="card shadow-sm mb-3 justify-content-center align-items-center pointer" (click)="cargarPrestadores(financiador)" [ngStyle]="(financiador.cuit === cuitFinanciadorSeleccionado) ? {'border-left': '4px solid green'} : {'border-left': '1px solid lightgrey'}">
                            <img src="./assets/img/{{financiador.sigla}}_LOGO.png" class="card-img-top" title="{{financiador.sigla}}" alt="{{financiador.sigla}}">
                        </div>
                    </ng-template>

                    <ng-template #financiadorDisable>
                        <div class="card mb-3 justify-content-center align-items-center" style="opacity: 0.3;">
                            <img src="./assets/img/{{financiador.sigla}}_LOGO.png" class="card-img-top" title="{{financiador.sigla}}" alt="{{financiador.sigla}}">
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div> -->
    </div>
  </div>
</div>
</html>

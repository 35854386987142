<div class="modal-header">
  <h4>{{titulo}}</h4>
</div>
<div class="modal-body">
  <div class="table-responsive mt-2">
    <table class="table table-striped table-bordered table-sm">
      <thead>
        <th class="text-center">Nombre</th>
        <th class="text-center">Teléfono</th>
        <th class="text-center">Logo</th>
      </thead>
      <tbody>
        <tr *ngFor="let financiador of financiadores">
          <td class="text-center">{{financiador.sigla}}</td>
          <td class="text-center">{{financiador.telefono}}</td>
          <td class="text-center" style="width: 30%;"><img src="./assets/img/{{financiador.sigla}}_LOGO.png" width="50"
              height="50" class="card-img-top" title="{{financiador.sigla}}" alt="Nuvalid {{financiador.sigla}}"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="devolverRespuesta()" type="submit" class="btn btn-pcpos" ngbAutofocus>OK</button>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { IntroduccionComponent } from './configuracionInicial/introduccion/introduccion.component';
import { InstalacionComponent } from './configuracionInicial/instalacion/instalacion.component';
import { SelectLugarAtencionComponent } from './configuracionInicial/instalacion/select-lugar-atencion/select-lugar-atencion.component';
import { LoginComponent } from './configuracionInicial/login/login.component';
import { SignupComponent } from './configuracionInicial/signup/signup.component';
import { ContactanosComponent } from './configuracionInicial/contactanos/contactanos.component';
import { AppRoutingModule } from './app.routing';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { appReducers } from '@store/app.reducers';
import { effectsArr } from '@store/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from '@pages/pages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IconsModule } from 'assets/icons/icons.module';
import { ComponentsModule } from '@components/components.module';
import { SharedModule } from '@shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { solicitarInfoModalComponent } from './modals/solicitar-info-modal/solicitar-info-modal.component';
import { ConfirmarPrestadoresModalComponent } from './modals/confirmar-prestadores-modal/confirmar-prestadores-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { VerAdministradoresModalComponent } from './modals/ver-administradores-modal/ver-administradores-modal.component';
import { ConfirmarModalComponent } from './modals/confirmar-modal/confirmar-modal.component';
import { CambiarContrasennaModalComponent } from './modals/cambiar-contrasenna-modal/cambiar-contrasenna-modal.component';
import { TicketModalComponent } from './modals/ticket-modal/ticket-modal.component';
import { InfoFinanciadoresComponent } from './modals/info-financiadores/info-financiadores.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { CscModalComponent } from './modals/csc-modal/csc-modal.component';
import { NewVersionModalComponent } from './modals/new-version-modal/new-version-modal.component';
import { WorkInProgressModalComponent } from './modals/work-in-progress-modal/work-in-progress-modal.component';
import { DownloadModalComponent } from './modals/download-modal/download-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SaveMatriculaModalComponent } from './modals/save-matricula-modal/save-matricula-modal.component';
import { CannotPrescribeModalComponent } from './modals/cannot-prescribe-modal/cannot-prescribe-modal.component';
import { NewChangesModalComponent } from './modals/new-changes-modal/new-changes-modal.component';
import { UniqueCuitPipe } from './pipes/unique-cuit.pipe';

// import {ModalModule} from 'ngx-bootstrap/modal';

// import { LottieAnimationViewModule } from 'ng-lottie';

@NgModule({
  declarations: [
    AppComponent,
    IntroduccionComponent,
    ContactanosComponent,
    InstalacionComponent,
    SelectLugarAtencionComponent,
    LoginComponent,
    SignupComponent,
    solicitarInfoModalComponent,
    ConfirmarPrestadoresModalComponent,
    VerAdministradoresModalComponent,
    ConfirmarModalComponent,
    CambiarContrasennaModalComponent,
    TicketModalComponent,
    InfoFinanciadoresComponent,
    ReplacePipe,
    CscModalComponent,
    NewVersionModalComponent,
    WorkInProgressModalComponent,
    DownloadModalComponent,
    SaveMatriculaModalComponent,
    CannotPrescribeModalComponent,
    NewChangesModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    ComponentsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // LottieAnimationViewModule,
    EffectsModule.forRoot(effectsArr),
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    IconsModule,
    NgbModule,
  ],
  providers: [],
  entryComponents: [TicketModalComponent, ConfirmarPrestadoresModalComponent, solicitarInfoModalComponent, VerAdministradoresModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}

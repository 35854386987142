import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { InicioComponent } from './inicio/inicio.component';
import { PagesComponent } from './pages.component';
import { PerfilComponent } from './perfil/perfil.component';
import { IconsModule } from 'assets/icons/icons.module';
import { AceptarUsuarioComponent } from './perfil/operaciones-usuarios/aceptar-usuario/aceptar-usuario.component';
import { ComponentsModule } from '@components/components.module';
import { EliminarUsuarioComponent } from './perfil/operaciones-usuarios/eliminar-usuario/eliminar-usuario.component';
import { AceptarCambiosUsuarioComponent } from './pages.index';
import { ModificarUsuarioComponent } from './perfil/operaciones-usuarios/modificar-usuario/modificar-usuario.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CambiarRolUsuariosComponent } from './perfil/operaciones-usuarios/cambiar-rol-usuarios/cambiar-rol-usuarios.component';
import { AgendaComponent } from './agenda/agenda.component';
import { CrearContactoComponent } from './agenda/crear-contacto/crear-contacto.component';
import { FilterPipe } from 'app/pipes/filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivarUsuariosComponent } from './perfil/operaciones-usuarios/activar-usuarios/activar-usuarios.component';
import { OrdenarTransaccionesComponent } from './perfil/operaciones-usuarios/ordenar-transacciones/ordenar-transacciones.component';
import { MaterialModule } from 'app/material/material.module';
import { SeleccionarPrestadoresNoDeseadosComponent } from './perfil/operaciones-usuarios/seleccionar-prestadores-no-deseados/seleccionar-prestadores-no-deseados.component';
import { TicketComponent } from './ticket/ticket.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SugerenciaComponent } from './sugerencia/sugerencia.component';
import { TurneaComponent } from './turnea/turnea.component';

@NgModule({
  declarations: [
    InicioComponent,
    PagesComponent,
    PerfilComponent,
    AceptarUsuarioComponent,
    AceptarCambiosUsuarioComponent,
    EliminarUsuarioComponent,
    ModificarUsuarioComponent,
    CambiarRolUsuariosComponent,
    AgendaComponent,
    CrearContactoComponent,
    FilterPipe,
    ActivarUsuariosComponent,
    OrdenarTransaccionesComponent,
    SeleccionarPrestadoresNoDeseadosComponent,
    TicketComponent,
    SugerenciaComponent,
    TurneaComponent
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    // SharedModule,
    IconsModule,
    MaterialModule,
    NgbModule,
    BsDatepickerModule
  ],
})
export class PagesModule {}

<!-- <div class="modal-header">
    <h4>{{mensajeAMostrar}}</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-row">
            <ng-container *ngIf="tipoDatosAPedir=='Email'">
                <div class="form-group col-12">
                    <input type="email" name="email" placeholder="Email" autocomplete="off" class="form-control"
                        formControlName="email">

                    <small *ngIf="submitted && form.controls['email'].errors"
                        class="text-danger animated fadeIn form-text ">Debe
                        ingresar un email valido</small>
                </div>

            </ng-container>

            <ng-container *ngIf="(tipoDatosAPedir=='Rol' || tipoDatosAPedir=='RolTodos') && roles">
                <div class="form-group col-12">
                    <label>Rol</label>
                    <select [ngClass]="{disabled: roles.length <= 1}" formControlName="rol"
                        class="form-select form-control">
                        <option *ngFor="let rol of roles" value="{{rol}}">
                            {{rol}}
                        </option>
                    </select>
                </div>

                <div class="form-group col-12" *ngIf="MostrarSeleccionarPrestador">
                    <label>Seleccione con que prestador se desea trabajar</label>
                    <select [ngClass]="{disabled: roles.length <= 1}" formControlName="cuitPrestadorSeleccionado"
                        class="form-select form-control">
                        <option *ngFor="let prestador of prestadoresLugarAtencion" value="{{prestador.cuit}}">
                            {{prestador.razon_social}}</option>
                    </select>
                </div>
            </ng-container>

            <ng-container *ngIf="tipoDatosAPedir=='LugaresAtencion' && lugaresDeAtencion">
                <div class="form-group col-12">
                    <label>Lugar de atención</label>
                    <select [ngClass]="{disabled: lugaresDeAtencion.length <= 1}" formControlName="lugarAtencion"
                        class="form-select form-control">
                        <option *ngFor="let lugarAtencion of lugaresDeAtencion"
                            value="{{lugarAtencion.codigoInstalacion}}">
                            {{lugarAtencion.domicilio}}
                        </option>
                    </select>
                </div>

            </ng-container>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button (click)="devolverRespuesta(false)" type="submit" class="btn btn-danger mr-2">Cancelar</button>
    <button (click)="devolverRespuesta(true)" type="submit" class="btn btn-pcpos" ngbAutofocus>Enviar</button>
</div> -->


<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->


</head>



<main class="cardN" id="cardNRestablecer">
    <!-- <div class="card__columnL" id="card__columnLP">
        <figure class="card__logoNuvalidC">
            <img src="/assets/design/logoNuvalid.svg" alt="logoNuvalid" class="card__logoNuvalid">
            <div id="logoN"> </div>
        </figure>
        <figure class="card__phoneC">
            <a href="#" id="phone"></a>
            <a href="#"><img src="/assets/phone.svg" alt="phoneNuvalid" class="card__phone"></a>
        </figure>
    </div> -->

    <div class="card__columnR " id="card__columnR-loginP">
        <form [formGroup]="form" class="form-login" id="form-loginP">
            <ng-container *ngIf="tipoDatosAPedir=='Email'">
                <div class="illustrations">
                    <img src="./assets/design/docPassword.svg" alt="Nuvalid Ilustración Doctor" class="img__loginP" id="docPassword">
                </div>
                <div class="text__password text__passwordRestablecer">
                    <h3 class="tittleP">Restablecer contraseña</h3>
                </div>



                <div class="reminder-passwordC reminder-passwordCRestablecer">
                    <div class="lineP"></div>

                    <p class="reminder-password reminder-passwordRestablecer">{{mensajeAMostrar}}</p>
                </div>
                <div class="data-login" id="data-loginP">
                    <label for="email" id="label-email">
                        <input type="email" name="email" placeholder="Email" autocomplete="off" class="form-control"
                            formControlName="email">

                        <small *ngIf="submitted && form.controls['email'].errors"
                            class="text-danger animated fadeIn form-text ">Debe
                            ingresar un email valido</small>
                    </label>
                </div>

            </ng-container>
            <ng-container *ngIf="(tipoDatosAPedir=='Rol' || tipoDatosAPedir=='RolTodos') && roles">
                <div class="illustrations">
                    <img src="./assets/design/docPassword.svg" alt="Nuvalid Ilustración Doctor" class="img__loginP" id="docPassword">
                </div>
                <div class="text__password">
                    <h3 class="tittleP">Roles</h3>
                </div>



                <div class="reminder-passwordC">
                    <div class="lineP"></div>

                    <p class="reminder-password">{{mensajeAMostrar}}</p>
                </div>

                <div class="data-login" id="data-loginP">
                    <label>Rol</label>
                    <select [ngClass]="{disabled: roles.length <= 1}" formControlName="rol"
                        class="form-select form-control">
                        <option *ngFor="let rol of roles" value="{{rol}}">
                            {{rol}}
                        </option>
                    </select>
                </div>

                <div class="data-login" *ngIf="MostrarSeleccionarPrestador">
                    <label>Seleccione con que prestador se desea trabajar</label>
                    <select [ngClass]="{disabled: roles.length <= 1}" formControlName="cuitPrestadorSeleccionado"
                        class="form-select form-control">
                        <option *ngFor="let prestador of prestadoresLugarAtencion" value="{{prestador.cuit}}">
                            {{prestador.razon_social}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="tipoDatosAPedir=='LugaresAtencion' && lugaresDeAtencion">
                <div class="illustrations">
                    <img src="./assets/design/docPassword.svg" alt="Nuvalid Ilustración Doctor" class="img__loginP" id="docPassword">
                </div>
                <div class="text__password">
                    <h3 class="tittleP">Ver Administradores</h3>
                </div>



                <div class="reminder-passwordC">
                    <div class="lineP"></div>

                    <p class="reminder-password">{{mensajeAMostrar}}</p>
                </div>
                <div class="data-login" id="data-loginP">
                    <label>Lugar de atención</label>
                    <select [ngClass]="{disabled: lugaresDeAtencion.length <= 1}" formControlName="lugarAtencion"
                        class="form-select form-control">
                        <option *ngFor="let lugarAtencion of lugaresDeAtencion"
                            value="{{lugarAtencion.codigoInstalacion}}">
                            {{lugarAtencion.domicilio}}
                        </option>
                    </select>
                </div>

            </ng-container>

            <div class="components components-loginP" id="components-loginPRestablecer">
                <button (click)="devolverRespuesta(false)" type="submit" class="botton bottonP">Cancelar</button>
                <button (click)="devolverRespuesta(true)" type="submit" class="botton bottonP"
                    ngbAutofocus>Enviar</button>
                <!-- <button class="botton bottonP">Enviar</button> -->
            </div>

        </form>

    </div>


</main>



<script src="js/lottiePhone.js"></script>
<script src="js/index.js"></script>




</html>

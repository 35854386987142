<!-- <div class="fondo">
    <div class="container-xl" style="margin-top: 10vh;">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center"><img src="./assets/img/logo.svg" style="height: 70px;"></div>
        </div>

        <div class="row mt-5 justify-content-center animated fadeInLeft">
            <div class="card col-12 col-md-10 col-lg-8 p-0 border-0 boxShadow">
                <div class="card-header p-0 bg-navegation rounded-top">
                    <app-navegation [volverAtrasAuth]="false" [volverALoginAuth]="true" (siguienteClick)="getCodigo()">
                    </app-navegation>
                </div>
                <div class="card-body bg-login rounded p-5">
                    <form [formGroup]="form" class="p-3" (ngSubmit)="getCodigo()" action="aplicaciones">
                        <div class="form-group">
                            <div class="form-row">
                                <h5>Introduzca el cuit de algún prestador que trabaje en el lugar
                                </h5>
                                <input type="text" maxlength="11 " type="text " name="CUIT" placeholder="CUIT" autocomplete="off" class="form-control mt-2" formControlName="cuit" [ngClass]="{'is-invalid': submitted && form.controls['cuit'].errors}" [ngClass]="{'is-valid': !form.controls['cuit'].errors}">

                                <small *ngIf="submitted && form.controls['cuit'].errors" class="text-danger animated fadeIn form-text ">Debe
                  ingresar 11
                  caracteres numéricos sin guiones</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="containerNNav">
  <app-navbar-windows class="app-navbar-windows"></app-navbar-windows>
  <main class="cardN" id="cardNInst">
    <div class="card__columnL">
      <figure class="card__logoNuvalidC">
        <img src="./assets/design/logoNuvalid.svg" alt="Logo Nuvalid" class="card__logoNuvalidC pointer"
          (click)="irALogin()">
        <!-- <div id="logoN"> </div> -->
      </figure>
      <figure class="card__phoneC">
        <a class="pointer" id="phone"><img src="./assets/design/phone.svg" alt="Nuvalid Ícono Celular" class="card__phoneC"
            (click)="contacto()">
        </a>
        <!-- <a href="#"><img src="/assets/phone.svg" alt="phoneNuvalid" class="card__phone"></a> -->
      </figure>
    </div>
    <div class="card__columnR">
      <form [formGroup]="form" (ngSubmit)="getCodigo()" action="aplicaciones">
        <div>
          <h5 class="form-textN"><span class="form-text__bold">Bienvenido.</span> Por favor ingrese el
            n&uacute;mero de CUIT de alg&uacute;n prestador que trabaje en la direcci&oacute;n donde
            desea instalar Nuvalid.
          </h5>
          <div class="form-infoN">
            <label for="CUIT">CUIT</label>
            <input type="text" id="CUIT" maxlength="11 " type="text " name="CUIT" placeholder="Ej.: 22999999992"
              autocomplete="off" class="form-controlN" formControlName="cuit"
              [ngClass]="{'is-invalid': submitted && form.controls['cuit'].errors}">
          </div>

          <div class="reminder" *ngIf="mostrando || form.valid">
            <div class="reminder__warning">
              <img src="./assets/design/warning.svg" alt="Nuvalid Ícono Precaución" id="warning__img">
            </div>
            <p class="reminder__text">
              Recuerde ingresar 11 caracteres num&eacute;ricos sin guiones. Si no recuerda su CUIT,
              puede consultarlo aqu&iacute;:
              <a href="https://www.argentina.gob.ar/buscar/CUIT" target="_blank" class="reminder__link">
                https://www.argentina.gob.ar/buscar/CUIT
              </a>
            </p>
          </div>

          <div class="form-textN" style="margin-top: 1em;">
            <span class="form-text__bold">¿Necesitas ayuda?</span> Descarga el <span class="form-text__bold">Manual de
              Uso de Nuvalid</span> y encuentra toda la información que necesitas. <a
              href="https://wiki.itcsoluciones.com/images/Nuvalid-Guía-de-uso.pdf" class="reminder__link"
              target="_blank">Descargar</a>
          </div>

          <div class=" reminder" id="reminder--error" *ngIf="submitted && form.controls['cuit'].errors">
            <div class="reminder__warning" id="containerAlert__img">
              <img src="./assets/design/alert.svg " alt="Nuvalid Ícono Alerta" id="alert_img ">
            </div>
            <div class="reminder--error">
              <p class="reminder__text reminder__text--error">
                Recuerde ingresar 11 caracteres num&eacute;ricos sin guiones. Si no recuerda su
                CUIT, puede consultarlo aqu&iacute;:
                <a href="https://www.argentina.gob.ar/buscar/CUIT" target="_blank" class="reminder__link"
                  id="reminder__link--error">
                  https://www.argentina.gob.ar/buscar/CUIT</a>
              </p>
            </div>
          </div>
        </div>
      </form>

      <div class="components">
        <!-- <div *ngIf="submitted "> -->
        <app-navegation [next]="false" [volverALoginAuth]="true"></app-navegation>
        <!-- </div> -->
        <!-- <img src="../../assets/doctora.svg " alt="doctor Nuvalid " class="components__img "> -->
        <div id="doctora"> <img src="./assets/design/doctora.svg " alt="Nuvalid Ilustración Doctor"
            class="components__imgDoctora "></div>

        <app-navegation [next]="true" [volverAtrasAuth]="false" [volverALoginAuth]="false"
          (siguienteClick)="getCodigo()">Continuar</app-navegation>
      </div>
    </div>

  </main>
</div>

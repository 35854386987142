import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './instalacion/map/map.component';
import { SearchAutocompleteComponent } from './instalacion/search-autocomplete/search-autocomplete.component';
import { SharedModule } from '@shared/shared.module';
import { NavegationComponent } from './instalacion/navegation/navegation.component';
import { IconsModule } from 'assets/icons/icons.module';
import { OperacionesUsuariosComponent } from './operaciones-usuarios/operaciones-usuarios.component';
import { OperacionesUsuarioComponent } from './operaciones-usuario/operaciones-usuario.component';
import { CardSociosComponent } from './inicio/card-socios/card-socios.component';
import { AfiliadoComponent } from './inicio/afiliado/afiliado.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputAgendaComponent } from './input-agenda/input-agenda.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BuscadorFilterComponent } from './buscador-filter/buscador-filter.component';
import { PrestadorComponent } from './inicio/prestador/prestador.component';
import { FilterPrestadoresByFinanciadorSelecPipe } from 'app/pipes/filter-prestadores-by-financiador-selec.pipe';
import { IMaskModule } from 'angular-imask';
import { FilterFinanciadorEnablePipe } from 'app/pipes/filter-financiador-enable.pipe';
import { CardTransaccionComponent } from './inicio/card-transaccion/card-transaccion.component';
import { DatosAtencionRequestComponent } from './inicio/datosTransacciones/datos-atencion-request/datos-atencion-request.component';
import { DatosBeneficiarioRequestComponent } from './inicio/datosTransacciones/datos-beneficiario-request/datos-beneficiario-request.component';
import { DatosCodFinalizacionTratamientoComponent } from './inicio/datosTransacciones/datos-cod-finalizacion-tratamiento/datos-cod-finalizacion-tratamiento.component';
import { DatosDiagnosticosRequestComponent } from './inicio/datosTransacciones/datos-diagnosticos-request/datos-diagnosticos-request.component';
import { DatosEfectorRequestComponent } from './inicio/datosTransacciones/datos-efector-request/datos-efector-request.component';
import { DatosMensajeParaFinanciadorComponent } from './inicio/datosTransacciones/datos-mensaje-para-financiador/datos-mensaje-para-financiador.component';
import { DatosPreautorizacionRequestComponent } from './inicio/datosTransacciones/datos-preautorizacion-request/datos-preautorizacion-request.component';
import { DatosPrescripcionRequestComponent } from './inicio/datosTransacciones/datos-prescripcion-request/datos-prescripcion-request.component';
import { DatosPrescriptorRequestComponent } from './inicio/datosTransacciones/datos-prescriptor-request/datos-prescriptor-request.component';
import { DatosDetalleProcedimientoRequestComponent } from './inicio/datosTransacciones/datos-detalle-procedimiento-request/datos-detalle-procedimiento-request.component';
import { DatosDetalleProcedimientoRequestFormComponent } from './inicio/datosTransacciones/datos-detalle-procedimiento-request/datos-detalle-procedimiento-request-form/datos-detalle-procedimiento-request-form.component';
import { InputEspecialidadesComponent } from './input-especialidades/input-especialidades.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AcompannanteRequestComponent } from './inicio/datosTransacciones/acompannante-request/acompannante-request.component';
import { DatosDerivacionRequestComponent } from './inicio/datosTransacciones/datos-derivacion-request/datos-derivacion-request.component';
import { DatosDocumentacionRequestComponent } from './inicio/datosTransacciones/datos-documentacion-request/datos-documentacion-request.component';
import { InputPrestacionesComponent } from './input-prestaciones/input-prestaciones.component';
import { ControlRequiredPipe } from '../pipes/control-required.pipe';
import { PrescripcionMedicamentoComponent } from './inicio/transacciones/prescripcion-medicamento/prescripcion-medicamento.component';
import { CardPrescripcionComponent } from './inicio/card-prescripcion/card-prescripcion.component';
import { InputMonodrogaComponent } from './input-monodroga/input-monodroga.component';
import { DatosMonodrogaRequestComponent } from './inicio/datosDispensa/datos-monodroga-request/datos-monodroga-request.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { InputDiagnosticosComponent } from './input-diagnosticos/input-diagnosticos.component';
import { DatosDiagnosticoDispRequestComponent } from './inicio/datosDispensa/datos-diagnostico-disp-request/datos-diagnostico-disp-request.component';
import { DatosIndicacionesComponent } from './inicio/datosDispensa/datos-indicaciones/datos-indicaciones.component';
import { DatosFirmaManuscritaComponent } from './inicio/datosDispensa/datos-firma-manuscrita/datos-firma-manuscrita.component';
import { DatosAnulacionRequestComponent } from './inicio/datosTransacciones/datos-anulacion-request/datos-anulacion-request.component';
import { CodigoDeSeguridadComponent } from './inicio/codigo-de-seguridad/codigo-de-seguridad.component';
import { InformarTransaccionComponent } from './inicio/transacciones/informar-transaccion/informar-transaccion.component';
import { NavbarWindowsComponent } from './navbar-windows/navbar-windows.component';
import { AnularPrestacionComponent } from './inicio/transacciones/anular-prestacion/anular-prestacion.component';
import { DatosInformarDiagnosticoRequestComponent } from './inicio/datosTransacciones/datos-informar-diagnostico-request/datos-informar-diagnostico-request.component';
import { DatosInformarCirugiaRequestComponent } from './inicio/datosTransacciones/datos-informar-cirugia-request/datos-informar-cirugia-request.component';
import { DatosAltaInternacionRequestComponent } from './inicio/datosTransacciones/datos-alta-internacion-request/datos-alta-internacion-request.component';
import { DatosInformarInternacionRequestComponent } from './inicio/datosTransacciones/datos-informar-internacion-request/datos-informar-internacion-request.component';
import { DatosAutorizacionCirugiaRequestComponent } from './inicio/datosTransacciones/datos-autorizacion-cirugia-request/datos-autorizacion-cirugia-request.component';
import { DatosProtQuirurgicoRequestComponent } from './inicio/datosTransacciones/datos-prot-quirurgico-request/datos-prot-quirurgico-request.component';
import { DatosNroReferenciaRequestComponent } from './inicio/datosTransacciones/datos-nro-referencia-request/datos-nro-referencia-request.component';
import { InputLoginComponent } from './input-login/input-login.component';
import { DatosEstudioRequestComponent } from './inicio/datosDispensa/datos-estudio-request/datos-estudio-request.component';
import { InputEstudioComponent } from './input-estudio/input-estudio.component';
import { DatosRescateRecetaComponent } from './inicio/datosTransacciones/datos-rescate-receta/datos-rescate-receta.component';
import { InformarPrestacionRescateComponent } from './inicio/datosTransacciones/informar-prestacion-rescate/informar-prestacion-rescate.component';



@NgModule({
  declarations: [
    MapComponent,
    SearchAutocompleteComponent,
    NavegationComponent,
    OperacionesUsuariosComponent,
    OperacionesUsuarioComponent,
    CardSociosComponent,
    AfiliadoComponent,
    InputAgendaComponent,
    BuscadorFilterComponent,
    ControlRequiredPipe,
    PrestadorComponent,
    FilterPrestadoresByFinanciadorSelecPipe,
    FilterFinanciadorEnablePipe,
    CardTransaccionComponent,
    DatosAtencionRequestComponent,
    DatosBeneficiarioRequestComponent,
    DatosCodFinalizacionTratamientoComponent,
    DatosDiagnosticosRequestComponent,
    DatosEfectorRequestComponent,
    DatosMensajeParaFinanciadorComponent,
    DatosPreautorizacionRequestComponent,
    DatosPrescripcionRequestComponent,
    DatosPrescriptorRequestComponent,
    DatosDetalleProcedimientoRequestComponent,
    DatosDetalleProcedimientoRequestFormComponent,
    InputEspecialidadesComponent,
    AcompannanteRequestComponent,
    DatosDerivacionRequestComponent,
    DatosDocumentacionRequestComponent,
    InputPrestacionesComponent,
    PrescripcionMedicamentoComponent,
    CardPrescripcionComponent,
    InputMonodrogaComponent,
    DatosMonodrogaRequestComponent,
    InputDiagnosticosComponent,
    DatosDiagnosticoDispRequestComponent,
    DatosIndicacionesComponent,
    DatosFirmaManuscritaComponent,
    DatosAnulacionRequestComponent,
    CodigoDeSeguridadComponent,
    InformarTransaccionComponent,
    NavbarWindowsComponent,
    AnularPrestacionComponent,
    DatosInformarDiagnosticoRequestComponent,
    DatosInformarCirugiaRequestComponent,
    DatosAltaInternacionRequestComponent,
    DatosInformarInternacionRequestComponent,
    DatosAutorizacionCirugiaRequestComponent,
    DatosProtQuirurgicoRequestComponent,
    DatosNroReferenciaRequestComponent,
    InputLoginComponent,
    DatosEstudioRequestComponent,
    InputEstudioComponent,
    DatosRescateRecetaComponent,
    InformarPrestacionRescateComponent,
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    SharedModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    IMaskModule,
    Ng2SearchPipeModule
  ],
  exports: [
    MapComponent,
    SearchAutocompleteComponent,
    NavegationComponent,
    OperacionesUsuarioComponent,
    OperacionesUsuariosComponent,
    CardSociosComponent,
    AfiliadoComponent,
    PrestadorComponent,
    InputAgendaComponent,
    BuscadorFilterComponent,
    CardTransaccionComponent,
    PrescripcionMedicamentoComponent,
    CardPrescripcionComponent,
    CodigoDeSeguridadComponent,
    InformarTransaccionComponent,
    NavbarWindowsComponent,
    InputLoginComponent,
  ],
})
export class ComponentsModule {}

<div class="container__datosInit">
  <nav class="navbar navbar-light">
    <h4 class="tittle__dashboard">Sugerencias</h4>
  </nav>
  <form [formGroup]="form">
    <!-- START DATOS PERSONALES -->
    <div class="filtrosTicket">
      <div class="row sugerenciasDatosPersonales">
        <div class="col">
          <div class="form-infoN">
            <label>Nombre </label>
            <input autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
              class="form-controlN" formControlName="nombre" />
          </div>
        </div>
        <div class="col">
          <div class="form-infoN">
            <label>Apellido </label>
            <input autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
              class="form-controlN" formControlName="apellido" />
          </div>
        </div>
        <div class="col">
          <div class="form-infoN">
            <label>Email
              <span *ngIf="form.get('email')" class="span-required">*</span></label>
            <input autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
              class="form-controlN" formControlName="email" />
            <small *ngIf="form.controls['email'].touched && form.controls['email'].errors"
              class="text-danger animated fadeIn">El email ingresado no es válido</small>
          </div>
        </div>
        <div class="col">
          <div class="form-infoN">
            <label>Teléfono </label>
            <input autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
              class="form-controlN" formControlName="telefono" />
            <small *ngIf="form.controls['telefono'].touched && form.controls['telefono'].errors"
              class="text-danger animated fadeIn">El teléfono ingresado no es válido</small>
          </div>
        </div>
        <!-- END DATOS PERSONALES -->
      </div>
      <!-- START MENSAJE -->
      <div class="sugerenciasM">
        <div class="row">
          <div class="form-infoN" style="margin-bottom: 55px; margin-top: 45px;">
            <label>Mensaje
              <span *ngIf="form.get('mensaje')" class="span-required">*</span></label>
            <textarea rows="5" autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
              class="form-controlN" style="width: 100%; resize: none;" formControlName="mensaje">
            </textarea>
            <small *ngIf="form.controls['mensaje'].touched && form.controls['mensaje'].errors"
              class="text-danger animated fadeIn">El mensaje ingresado no es válido</small>
          </div>
          <div class="reminderSugerencia reminder_afiliado">
            <div>
              <img src="./assets/design/warning.svg" alt="Nuvalid Ícono Precaución">
            </div>
            <p class="reminder__text" style="width: auto;">Recuerde que puede comunicarse con nuestro
              centro de soporte haciendo <a target="_blank" class="reminder__link" (click)="contacto()">click aquí.</a>
            </p>
          </div>
        </div>
      </div>
      <!-- END MENSAJE -->
    </div>

  </form>
</div>
<div class="container__enviarS">
  <button class="buttonBuscarTickets" (click)="enviarSugerencia()">
    Enviar
  </button>
</div>

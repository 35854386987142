import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { EfectorRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { setEfectorRequest, setEstadoFormEfectorRequest, unsetEfectorRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-efector-request',
  templateUrl: './datos-efector-request.component.html',
  styleUrls: ['./datos-efector-request.component.scss'],
})
export class DatosEfectorRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<EfectorRequestModel> =
    this.formBuilder.group<EfectorRequestModel>({
      ApellidoEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CuitEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Direccion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Email: new UntypedFormControl(<ControlState<string>>null, [blankSpaceValidator]),
      EspecialidadEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombreEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroMatriculaEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      ProvinciaEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoMatriculaEfector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  prestadores: Array<Prestador> = [];
  especialidades: Array<any> = [];
  provincias: Array<any> = [];
  tiposMatricula: Array<any> = [];
  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          // this.form.controls['TipoPrescriptor'].setValidators(
          //   Validators.required
          // );
          break;

        default:
          break;
      }
      //this.store.dispatch(new setEstadoFormEfectorRequest(this.form.valid));
    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.prestadores = datos.configuracion.datosIniciales?.prestadores || [];
      // this.prestadores = datos.configuracion.datosIniciales?.prestadores!.filter((prestador, index, self) =>
      //   index === self.findIndex(p => p.cuit === prestador.cuit)
      // ) ?? [];
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.provincias =
        datos.datosInicialesExtra.datosInicialesExtra?.provincia || [];
      this.tiposMatricula =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoMatricula || [];
      let especialidades =
        datos.datosInicialesExtra.datosInicialesExtra?.especialidades;
      let especialidadesUnidas = especialidades?.Bioquimica.concat(
        especialidades.Fisioterapia,
        especialidades.Kinesiologia,
        especialidades.Medicina,
        especialidades.Odontologia,
        especialidades.Psicologia
      );
      this.especialidades = especialidadesUnidas || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  selectChange(prestadorSeleccionado: Prestador | null) {

    if (prestadorSeleccionado) {
      this.form.controls['ApellidoEfector'].setValue(
        prestadorSeleccionado.razon_social
      );
      //console.log('entra efector',prestadorSeleccionado.cuit);
      this.form.controls['CuitEfector'].setValue(prestadorSeleccionado.cuit);
      this.form.controls['ProvinciaEfector'].setValue(
        prestadorSeleccionado.provincia || null
      );
    } else {
      this.form.controls['ApellidoEfector'].setValue(null);
      this.form.controls['CuitEfector'].setValue(null);
      this.form.controls['ProvinciaEfector'].setValue(null);

      //agregado 21-2-2022
      this.store.dispatch(new unsetEfectorRequest());
      this.store.dispatch(new setEstadoFormEfectorRequest(null));
      // fin agregado 21-2-2022
    }
  }

  selectEspecialidad(especialidad: any) {
    //Evento emitido desde el typeahead
    if (especialidad) {
      this.form.controls['EspecialidadEfector'].setValue(especialidad.codigo);
    } else {
      this.form.controls['EspecialidadEfector'].setValue(null);
    }
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let prescriptorRequest = new EfectorRequestModel(
      datos.ApellidoEfector,
      datos.CuitEfector,
      datos.Direccion,
      datos.Email,
      datos.EspecialidadEfector,
      datos.NombreEfector,
      datos.NroMatriculaEfector,
      datos.ProvinciaEfector,
      datos.TipoMatriculaEfector
    );
    this.store.dispatch(new setEfectorRequest(prescriptorRequest));
    this.store.dispatch(new setEstadoFormEfectorRequest(this.form.valid));
  }
}

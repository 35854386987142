<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
</head>
<div class="container__panel1">
  <div class="container__prestador">
    <div class="prestador-columnR">

      <form [formGroup]="form" class="prestador-form">
        <p class="tittle__dashboard tittle__prestador">Seleccione un prestador <span class="span-required">*</span>
        </p>

        <select id="optionPrestadores" class="form-control" formControlName="prestador" [compareWith]="compareFn"
          [attr.disabled]="!mostrar == true ? true : null">
          <option [ngValue]="null">
            Seleccionar un prestador
          </option>

          <ng-container
            *ngFor="let presta of prestadores | filterPrestadoresByFinanciadorSelec:cuitFinanciadorSeleccionado:usuarioActual:prestadoresSeleccionados">
            <option [ngValue]="presta">
              {{presta.razon_social}} - {{presta.financiadores[0].sigla}} -
              {{presta.financiadores[0].codigo_para_financiador}}
            </option>
          </ng-container>

        </select>

      </form>
      <div class="reminder reminderPrestador">
        <figure class="reminder__warningPrestador">
          <img src="./assets/design/warning.svg" alt="Nuvalid Botón Precaución">
        </figure>
        <p class="reminder__text reminder__textPrestador">Si el <span class="reminder__PrestadorB">Prestador</span> no
          se encuentra en la lista debe comunicarse con su financiador haciendo <a target="_blank"
            class="reminder__link" (click)="contacto()">
            click aquí.</a>
        </p>
      </div>
    </div>
    <figure class="illustrations-prestador">
      <img src="./assets/design/docPrestador.svg" alt="Nuvalid Ilustración Médico">
    </figure>
  </div>
</div>

</html>

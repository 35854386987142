<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>
<div class="container__datosInit">
    <!--agregue estilo, ver diana-->
    <nav class="navbar navbar-light ">
        <ng-container *ngIf="identificador == 'inicio' && siglaFinanciador && numCredencial && prestador; else operacion">
        <!-- <span class="span-required" *ngIf="required">*</span> -->
        <h4 class="tittle__dashboard">Financiador: {{ siglaFinanciador }} / Credencial: {{ numCredencial }} / Prestador: {{ prestador }}</h4>
        </ng-container>
        <ng-template #operacion>
                <p class="tittle__dashboard">{{ transaccion }}</p>
        </ng-template>


        <a href="#" class="arrowDownPanel" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + identificador"><img src="./assets/design/arrowdownPanel.svg" alt="Nuvalid Ícono Flecha"></a>


        <!--  <button class="navbar-toggler" type="button" data-toggle="collapse" [attr.data-target]="'#' + identificador">
    <fa-icon [icon]='["fas", "chevron-down"]'></fa-icon>
  </button> -->

        <div class="collapse navbar-collapse example-white" [id]=identificador>

            <ng-container *ngTemplateOutlet="componentePasado"></ng-container>

        </div>
    </nav>

</div>

</html>

<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>


<!-- <div class="container__panelPrestacion">
    <div class="container-prestacion">
        <div class="prestacion__cuadroCarga"> -->

<table class="table__prestaciones">
    <thead class="thead__prestaciones">
        <tr class="theadTr__prestaciones">
            <th class="theadTh__prestaciones">Código</th>
            <th class="theadTh__prestaciones">Descripción</th>
            <th class="theadTh__prestaciones">Cantidad</th>
            <th class="theadTh__prestaciones">Tipo</th>
            <th class="theadTh__prestaciones">Arancel</th>
            <th class="theadTh__prestaciones">Acciones</th>
        </tr>
    </thead>
    <tbody class="tbody__prestaciones">
        <ng-container
            *ngIf="detallesProcedimientos && detallesProcedimientos.length > 0; else noDetallesProcedimientos">
            <tr class="tbodyTr__prestaciones" *ngFor="let detalleProcedimiento of detallesProcedimientos; let i=index">
                <td class="tbodyTd__prestaciones">{{detalleProcedimiento.CodPrestacion}}</td>
                <td class="tbodyTd__prestaciones">{{detalleProcedimiento.DescripcionPrestacion}}</td>
                <td class="tbodyTd__prestaciones">{{detalleProcedimiento.CantidadSolicitada}}</td>
                <td class="tbodyTd__prestaciones">{{detalleProcedimiento.TipoPrestacion}}</td>
                <td class="tbodyTd__prestaciones">{{detalleProcedimiento.ArancelPrestacion}}</td>
                <td class="tbodyTd__prestaciones">
                    <!--
                    Saque la edicion, porque no la veo necesaria
                <a (click)="editar(i)">
                    <fa-icon [icon]='["fas", "edit"]' class="text-pcpos mx-2 pointer"></fa-icon>
                </a>
                -->
                    <figure class="prestacion_butonMore butonDelete">
                        <p><a href="#" (click)="eliminar(i)" data-title="Eliminar item"><img
                                    src="./assets/design/close.svg" alt="Nuvalid Botón Más"></a></p>

                    </figure>

                    <!--  <a (click)="eliminar(i)">
                                    <fa-icon [icon]='["fas", "trash-alt"]' class="text-pcpos mx-2 pointer">
                                    </fa-icon>
                                </a> -->

                </td>
            </tr>
        </ng-container>

        <ng-template #noDetallesProcedimientos>
            <tr class="theadTr__prestaciones">
                <td class="tbodyTd__prestaciones">Por favor cargue al menos una prestación</td>
            </tr>
        </ng-template>
    </tbody>
</table>
<!--         </div>
    </div>
</div> -->

</html>

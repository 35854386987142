import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { text } from '@fortawesome/fontawesome-svg-core';
import { redirectClass } from '@models/global.models';
import { roles } from '@models/signup.models';
import { Store } from '@ngrx/store';
import { removeDatosUsuario } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  private _ocultarModal: boolean = true;
  private _ocultarMenu: boolean = false;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toast: ToastrService
  ) { }

  get ocultarModal() {
    return this._ocultarModal;
  }

  get ocultarMenu() {
    return this._ocultarMenu;
  }

  abrirModal() {

    this._ocultarModal = false;


  }

  cerrarModal() {

    this._ocultarModal = true;

  }

  abrirMenu() {

    this._ocultarMenu = false;


  }

  cerrarMenu() {

    this._ocultarMenu = true;

  }

  redirect(redirectTo: redirectClass) {
    let navigationExtras: NavigationExtras = {
      state: {
        mensajeError: redirectTo.mensajeError,
        error: redirectTo.error,
        showAlert: redirectTo.showAlert,
      },
    };

    //muestra modal
    if (redirectTo.direccion === 'contactanos') {
      this.abrirModal();
    }
    this.router.navigate([redirectTo.direccion], navigationExtras);
  }

  //Se cordino con el back el manejo de errores
  //Si message existe es porque fue contemplado por el back
  //Si la respuesta es un 401 se acordo que esta respuesta siempre hara que se saque al usuario del sistema
  //Si es un 418 es un error grave y se lo redireccionara a contactanos para que hable con mesa de ayuda
  //Cualquier otro tipo de error se le mostrara un toast al usuario con el mensaje que nos enviaran
  manageError(error: HttpErrorResponse) {
    console.log(error);

    if (error.error?.message && error.error.message != 'No message available') {
      if (error.status == 401) {
        if (error.error.message === 'Su cuenta no ha sido activada por el administrador, por favor comuníquese con el') {
          let adminsData = error.error.administradores;
          let htmlContent = '<br>';
          for (const admin of adminsData) {
            htmlContent += `${admin.nombre} ${admin.apellido}, ${admin.email}<br>`;
          }
          this.error(error.error.message, 'Vuelve a iniciar sesión.', 'Administradores: ', htmlContent).then(() => {
            this.store.dispatch(new removeDatosUsuario());
          });
        } else {
          this.error(error.error.message, 'Vuelve a iniciar sesión.').then(() => {
            this.store.dispatch(new removeDatosUsuario());
          });
        }
      } else if (error.status == 418) {
        this.redirect(
          new redirectClass(
            'contactanos',
            error.error.message,
            error.error + ' , ' + error.message
          )
        );
      } else if (error.status == 406) {
        this.error('', error.error.message);
      } else {
        //this.error('', error.error.message);
        this.toastError(error.error.message);
      }
    } else {
      this.redirect(
        new redirectClass(
          'contactanos',
          errorGenerico,
          error.error + ' , ' + error.message
        )
      );
    }
  }

  //Array de roles para el sistema en el que estamos
  getRolesAMostrarByTipoInstalacion(
    role: string,
    tipoInstalacion: string
  ): Array<roles> {
    let array: Array<roles> = [];
    if (tipoInstalacion == 'NUVAMED') {
      array = ['Recepcion', 'Prestador'];
    } else if (tipoInstalacion == 'NUVAFARM') {
      array = ['Administracion', 'Farmaceutico'];
    }
    if (role == 'Administrador') {
      array.push('Administrador');
    }
    return array;
  }

  //Se trae estos metodo para aca para que no se produzca un circular dependecy
  toastError(mensaje: string) {
    this.toast.error(mensaje);
  }

  private error(title: string = '', msj: string = '', tituloAdmin: string = '', administradores: string = '') {
    return Swal.fire({
      icon: 'error',
      title: title,
      text: msj,
      // title: "La sesi&oacute;n ha expirado",
      // text: "Vuelve a inciar sesión",
      // footer: 'Vuelve a inciar sesión',
      html: tituloAdmin + '<br>' + administradores,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      // backdrop: '#96B2FF',
      confirmButtonColor: '#4459A3',
      background: '#f8f9ff',
      backdrop: `url("./assets/design/background.png") cover center no-repeat`,
      width: '33em'
    });
  }
}

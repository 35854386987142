import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

import { unAfiliadoAgenda } from '@models/agenda.models';
import {
  AfiliadoRequestModel,
  CredencialRequestModel,
  FinanciadorRequestModel,
  PrestadorRequestModel,
} from '@models/datosInicio.models';
import { Financiador } from '@models/financiador.model';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { InicioService } from '@services/inicio.service';
import {
  ActivarLoading,
  DesactivarLoading,
  setCredencialRequest,
  setDatosIniciales,
  setDatosInicioExtra,
  setFinanciadorRequest,
  unsetDatosTransacciones,
  unsetDatosInicio,
  setAfiliadoRequest,
  unsetCredencialRequest,
  unsetFinanciadorRequest,
  unsetPrestadorRequest,
  unsetTransaccioRequest,
  unsetAfiliadoRequest,
} from '@store/actions';
import {
  setEstadoFormCredencialRequest,
  setEstadoFormFinanciadorRequest,
  unsetEstadoFormDatos,
} from '@store/actions/estadoFormsinicio.action';
import { AppState } from '@store/app.reducers';
import {
  datosInicioState,
  datosTransaccionesState,
} from '@store/models/store.models';
import { Subscription } from 'rxjs';
import { estadoFormsState, UIState } from '../../store/models/store.models';
import { TransaccionesService } from '../../services/transacciones.service';
import { InputAgendaComponent } from '@components/input-agenda/input-agenda.component';
import { DesplegableComponent } from '../../shared/desplegable/desplegable.component';
import {
  setBeneficiarioRequest,
  setDetallesMedicamentosRequest,
  unsetDetallesMedicamentosRequest,
} from '../../store/actions/datosTransacciones.actions';
import { DetalleMedicamentosRequestModel } from '@models/datosTransaccion.models';
import { unsetEsRecetaRequest, unsetRecetasRequest } from '@store/actions/recetas.action';
import { NewChangesModalComponent } from 'app/modals/new-changes-modal/new-changes-modal.component';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit, AfterViewChecked {

  private subscriptionStore: Subscription = new Subscription();
  private subscriptionDatosStore: Subscription = new Subscription();
  private subscriptionDatosInicioStore: Subscription = new Subscription();
  private subscriptionUsuarioActual: Subscription = new Subscription();
  transacciones: Array<any> = [];
  prescripciones: Array<any> = [];
  datosInicio: datosInicioState | null = null;
  codigoTransaccionSeleccionada: string = '';
  codigoPrescripcionSeleccionada: string = '';
  datosTransaccion: datosTransaccionesState | null = null;
  estadoForms: estadoFormsState | null = null;
  isDataValid: boolean = false;
  mostrarCodigoSeguridad: boolean = false;
  escucharCambioEnTransaccion = false;
  siglaSwiss: boolean = false;

  financiadores: Array<Financiador> | null = null;
  cargando: boolean = false;
  mostrarOperaciones: boolean = false;
  ocultarDatosIniciales: boolean = false;
  ocultarOperaciones: boolean = false;
  mostrarPrescripciones: boolean = false;
  ocultarPrescripciones: boolean = false;
  mostrarBotones: boolean = false;

  @ViewChild(InputAgendaComponent) inputAgendaComponent!: InputAgendaComponent;
  @ViewChild('datosIniciales') datosInicialesDesplegable!: DesplegableComponent;
  parametroPrescripcion: any = {
    token: null,
    verificoPrescripcion: false,
    coordenadas: { lat: null, lng: null },
  };
  habilitaPrescripcion: boolean = false;
  @Input() selected: boolean | undefined;
  validarSinCSC: boolean = false
  //@ViewChild('pages', {static: false}) myScrollContainer: ElementRef | undefined;

  url = 'https://nuvalid.dev.puntosalud.ar';

  constructor(
    private inicioService: InicioService,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private transaccionesService: TransaccionesService,
    private store: Store<AppState>,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.cargarDatos();
    this.cargarDatosStore();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptionDatosStore.unsubscribe();
    this.subscriptionStore.unsubscribe();
    this.subscriptionUsuarioActual.unsubscribe();
  }

  scrollToElement() {
    setTimeout(() => {
      window.scroll({
        top: 1000 + window.pageYOffset,
        behavior: 'smooth',
      });
    }, 100);
  }

  cargarDatos() {
    this.dialogsService.cargando('Buscando información, por favor espere.');
    this.inicioService.configuracionInicial().subscribe(
      (respuesta) => {
        const noMostrarModal = localStorage.getItem('noMostrarModal');
        if (!noMostrarModal) {
          this.openNewChangesModal();
        }
        this.dialogsService.cerrar();
        if (window.location.origin == this.url) {
          this.dialogsService.infoHTML('IMPORTANTE', '<p>A partir del 14 de Octubre la versión de Nuvalid que esta utilizando dejara de funcionar.</p><p>Comunicarse con el Area de Soporte y Analisis para instalar la nueva versión.</p><p><b>0810-8102-482</b><p><p>Saludos Cordiales.</p><p>El equipo de Nuvalid.</p>');
        }

        let tiposPrescripciones = [
          { codigo: '1', nombre: 'Medicamento', cancelar: false },
          { codigo: '2', nombre: 'Análisis de Laboratorio', cancelar: true },
          { codigo: '3', nombre: 'Certificado Médico', cancelar: true },
          { codigo: '4', nombre: 'Diagnóstico por Imágenes', cancelar: true },
          { codigo: '5', nombre: 'Estudios', cancelar: true },
        ];

        if (respuesta) {
          let datosIniciales = {
            financiadores: respuesta.financiadores,
            prestadores: respuesta.prestadorDTOs,
            prestadoresSeleccionados: respuesta.prestadoresSeleccionados,
            //tiposTransacciones: [...respuesta.tipoTransacciones, prescripcion],
            tiposTransacciones: respuesta.tipoTransacciones,
            tiposPrescripciones: tiposPrescripciones,
          };
          console.log('Tipos Transacciones:', datosIniciales);

          this.store.dispatch(new setDatosIniciales(datosIniciales));
        }
      },
      (error) => {
        this.dialogsService.cerrar();
        this.globalService.manageError(error);
      }
    );
    this.inicioService.getDatosInicialesExtra().subscribe(
      (respuesta) => {
        if (respuesta) {
          this.store.dispatch(new setDatosInicioExtra(respuesta));
        }
      },
      (error) => {
        this.dialogsService.cerrar();
        this.globalService.manageError(error);
      }
    );

  }

  cargarDatosStore() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.cargando = datos.ui.loading;
      this.parametroPrescripcion.coordenadas = {
        lat: '' + datos.ui.coordenadas.lat() || null,
        lng: '' + datos.ui.coordenadas.lng() || null,
      };
      this.habilitaPrescripcion = datos.datosInicio.PrestadorRequest?.pescribir
        ? true
        : false;
      this.estadoForms = datos.estadoFormsInicio;
      this.datosTransaccion = datos.datosTransaccion;
      this.transacciones =
        (this.habilitaPrescripcion
          ? datos.configuracion.datosIniciales?.tiposTransacciones
          : datos.configuracion.datosIniciales?.tiposTransacciones?.filter(
            (result) => result.codigo !== '02R'
          )) || [];
      this.prescripciones = datos.configuracion.datosIniciales?.tiposPrescripciones || [];
      this.validateData();

      if (
        this.estadoForms.formEstadoPrestadorRequest &&
        this.estadoForms.formEstadoFinanciadorRequest &&
        this.estadoForms.formEstadoCredencialRequest
      ) {
        this.mostrarOperaciones = true;
        this.ocultarDatosIniciales = true;
        if (this.estadoForms.formEstadoPrescripcionDispensaRequest) {
          this.mostrarPrescripciones = true;
          if (this.datosTransaccion?.DetalleMedicamentosRequest) {
            this.scrollToElement();
          }
        } else {
          this.mostrarPrescripciones = false;
        }
      } else {
        this.mostrarOperaciones = false;
        this.ocultarDatosIniciales = false;
        this.mostrarPrescripciones = false;
      }

      if (this.estadoForms.formEstadoTransaccionRequest) {
        this.ocultarOperaciones = true;
        this.mostrarBotones = true;
      } else {
        this.ocultarOperaciones = false;
        this.mostrarBotones = false;
      }

      if (this.estadoForms.formEstadoPrescripcionDispensaRequest) {
        this.ocultarPrescripciones = true;
      } else {
        this.ocultarPrescripciones = false;
      }

      if (datos.datosInicio.FinanciadorRequest?.SiglaFinanciador === 'SWISS') {
        this.siglaSwiss = true;
      } else {
        this.siglaSwiss = false
      }

      this.datosInicio = datos.datosInicio;
      if (
        this.codigoTransaccionSeleccionada !=
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion
      ) {
        this.codigoTransaccionSeleccionada =
          datos.datosInicio.TransaccionRequest?.CodigoTransaccion || '';
        if (this.codigoTransaccionSeleccionada == "01A" || this.codigoTransaccionSeleccionada == "02R") {
          this.escucharCambioEnTransaccion = true;
        } else {
          this.escucharCambioEnTransaccion = false;
        }

      }

      if (this.escucharCambioEnTransaccion) {
        this.validateData();
        if (this.isDataValid) {
          if (this.codigoTransaccionSeleccionada == '01A' && this.mostrarCodigoSeguridad) {
            console.log('Verificar!!!');
            this.informarPrestacionCsc();
          } else if (this.codigoTransaccionSeleccionada == '02R') {
            this.tiposPrescripcion();
          } else {
            this.informarPrestacion();
          }
          this.escucharCambioEnTransaccion = false;
        }
      }

    });

    this.subscriptionDatosStore = this.store
      .select('configuracion')
      .subscribe((datos) => {
        if (datos.datosIniciales) {
          this.financiadores = datos.datosIniciales.financiadores;
        }
      });

    this.subscriptionDatosInicioStore = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        if (
          datos.PrescripcionDispensaRequest?.CodigoPrescripcion !=
          this.codigoPrescripcionSeleccionada
        ) {
          this.store.dispatch(new unsetDetallesMedicamentosRequest());
          this.codigoPrescripcionSeleccionada =
            datos.PrescripcionDispensaRequest?.CodigoPrescripcion || '';
        }

        //Solicitar codigo de seguridad
        if (this.datosInicio?.FinanciadorRequest?.SiglaFinanciador == 'OSDE' || this.datosInicio?.FinanciadorRequest?.SiglaFinanciador == 'SWISS' || this.datosInicio?.FinanciadorRequest?.SiglaFinanciador == 'SCIS') {
          this.mostrarCodigoSeguridad = true;
        } else {
          this.mostrarCodigoSeguridad = false;
        }

        if (this.datosInicio?.FinanciadorRequest?.SiglaFinanciador == 'SWISS' || this.datosInicio?.FinanciadorRequest?.SiglaFinanciador == 'SCIS') {
          this.validarSinCSC = true;
        }
      });
  }

  tiposPrescripcion() {
    console.log('Prescribir');
  }

  selectAfiliadoAgenda(afiliadoAgenda: unAfiliadoAgenda) {
    let financiador = this.financiadores?.find(
      (x) => x.sigla == afiliadoAgenda.nombreFinanciador
    );

    if (financiador) {
      let financiadorRequest = new FinanciadorRequestModel(
        financiador.codigo_empresa,
        financiador.cuit,
        financiador.sigla
      );
      this.store.dispatch(new setFinanciadorRequest(financiadorRequest));
      this.store.dispatch(new setEstadoFormFinanciadorRequest(true));
    }

    let credencialModel = new CredencialRequestModel(
      'M',
      '',
      afiliadoAgenda.numeroAfiliado,
      '',
      '',
      ''
    );

    this.store.dispatch(new setCredencialRequest(credencialModel));
    this.store.dispatch(new setEstadoFormCredencialRequest(true));
  }

  validateData() {
    if (
      !this.datosInicio ||
      !this.datosTransaccion ||
      this.estadoForms?.formEstadoAcompannanteRequest == false ||
      this.estadoForms?.formEstadoAtencionRequest == false ||
      this.estadoForms?.formEstadoBeneficiarioRequest == false ||
      this.estadoForms?.formEstadoCodFinalizacionTratamientoRequest == false ||
      this.estadoForms?.formEstadoCredencialRequest == false ||
      this.estadoForms?.formEstadoDerivacionRequest == false ||
      this.estadoForms?.formEstadoDetalleMedicamentosRequest == false ||
      this.estadoForms?.formEstadoDetalleProcedimientoRequest == false ||
      this.estadoForms?.formEstadoDiagnosticosRequest == false ||
      this.estadoForms?.formEstadoDocumentacionRequest == false ||
      this.estadoForms?.formEstadoEfectorRequest == false ||
      this.estadoForms?.formEstadoFechaReferenciaCancelRequest == false ||
      this.estadoForms?.formEstadoFinanciadorRequest == false ||
      this.estadoForms?.formEstadoIdMsjRequest == false ||
      this.estadoForms?.formEstadoInicioTrxRequest == false ||
      this.estadoForms?.formEstadoMensajeParaFinanciadorRequest == false ||
      this.estadoForms?.formEstadoNroReferenciaCancelRequest == false ||
      this.estadoForms?.formEstadoPreautorizacionRequest == false ||
      this.estadoForms?.formEstadoPrescripcionRequest == false ||
      this.estadoForms?.formEstadoPrescriptorRequest == false ||
      this.estadoForms?.formEstadoPrestadorRequest == false ||
      this.estadoForms?.formEstadoSoftwareRequest == false ||
      this.estadoForms?.formEstadoTerminalRequest == false ||
      this.estadoForms?.formEstadoTipoTransaccionRequest == false ||
      this.estadoForms?.formEstadoTransaccionRequest == false ||
      this.estadoForms?.formEstadoVersionMsjRequest == false ||
      this.estadoForms?.fomrEstadoDatosEnvioPrescripcionEmailBeRequest == false ||
      this.estadoForms?.fomrEstadoDatosEnvioPrescripcionDiagRequest == false ||
      this.estadoForms?.formEstadoFirmaRequest == false
      //(this.estadoForms?.fomrEstadoDatosEnvioPrescripcionEmailBeRequest == false || this.estadoForms?.fomrEstadoDatosEnvioPrescripcionDiagRequest == null) ||
      //(this.estadoForms?.fomrEstadoDatosEnvioPrescripcionDiagRequest == false || this.estadoForms?.fomrEstadoDatosEnvioPrescripcionDiagRequest == null)
    ) {
      this.isDataValid = false;
    } else {
      this.isDataValid = true;
    }
  }

  //PARA OPERACIONES QUE REQUIERAN CODIGO DE SEGURIDAD
  informarPrestacionCsc() {
    this.validateData();
    if (!this.isDataValid || this.estadoForms?.formEstadoCodigoDeSeguridadRequest == false) {
      this.dialogsService.toastError(
        'No se seleccionaron todos los datos necesarios para realizar la ' +
        (this.codigoTransaccionSeleccionada !== '02R'
          ? 'prestación'
          : 'prescripción')
      );
      return;
    }

    if (this.mostrarCodigoSeguridad && !this.validarSinCSC) {
      this.dialogsService.cscModal().result.then((resp: any) => {
        if (resp) {
          this.informarPrestacion();
        }
      });
    }

    if (this.validarSinCSC) {
      if (this.mostrarCodigoSeguridad) {
        this.dialogsService.cscModal().result.then((resp: any) => {
          if (resp) {
            this.informarPrestacion();
          }
        });
      }
    }
  }

  async informarPrestacion() {
    this.validateData();
    console.log(!this.isDataValid, this.estadoForms?.formEstadoCodigoDeSeguridadRequest);

    if (this.codigoTransaccionSeleccionada === '02Q') {
      if (!this.isDataValid || this.estadoForms?.formEstadoPreautorizacionRequest == null) {
        this.dialogsService.toastError(
          'No se seleccionaron todos los datos necesarios para realizar la prestación'
        );
        return;
      }
    }

    if (!this.isDataValid || this.estadoForms?.formEstadoCodigoDeSeguridadRequest == false) {
      this.dialogsService.toastError(
        'No se seleccionaron todos los datos necesarios para realizar la ' +
        (this.codigoTransaccionSeleccionada !== '02R'
          ? 'prestación'
          : 'prescripción')
      );
      return;
    }

    this.store.dispatch(new ActivarLoading());
    if (this.codigoTransaccionSeleccionada !== '02R') {
      this.dialogsService.cargando('Realizando transacción, por favor espere.');
      let respuesta = await this.realizarTransaccion();
      this.store.dispatch(new DesactivarLoading());
      this.dialogsService.cerrar();
      console.log('RESPUESTA: ', respuesta);
      if (respuesta.EncabezadoAtencion.Beneficiario) {
        let afiliadoModel = new AfiliadoRequestModel(
          respuesta.EncabezadoAtencion.Beneficiario.NombreBeneficiario || '',
          respuesta.EncabezadoAtencion.Beneficiario.ApellidoBeneficiario || '',
          respuesta.EncabezadoAtencion.Beneficiario.NroDocBeneficiario || '',
          respuesta.EncabezadoAtencion.Beneficiario.Email || '',
          '',
          respuesta.EncabezadoAtencion.Credencial.NumeroCredencial || '',
          respuesta.EncabezadoMensaje.Financiador.CodigoFinanciador || '',
          respuesta.EncabezadoMensaje.Financiador.CuitFinanciador || '',
          respuesta.EncabezadoMensaje.Financiador.SiglaFinanciador || ''
        );
        this.store.dispatch(new setAfiliadoRequest(afiliadoModel))
      }
      if (respuesta) {
        // console.log('la respusta antes del showTicketModal', respuesta);
        this.dialogsService.showTicketModal(
          this.datosInicio?.TransaccionRequest?.NombreTransaccion || '',
          respuesta,
          this.datosInicio!,
          this.datosTransaccion!
        );
        if (respuesta.EncabezadoMensaje.Rta?.CodRtaGeneral === '000' || this.codigoTransaccionSeleccionada === '01A') {
          this.limpiarDatos();
        }
      } else {
        this.limpiarDatos();
        this.dialogsService.toastError(
          'Se produjo un error, vuelva a intentarlo más tarde'
        );
      }
    } else {
      this.dialogsService.cargando(
        'Prescripción en curso..., por favor espere.'
      );
      this.parametroPrescripcion.verificoPrescripcion = true;

      if (this.codigoPrescripcionSeleccionada === '1') {
        //Si justifa las marcas el profesional actualizo el estado aca
        let nuevoArray: DetalleMedicamentosRequestModel[] = [];
        this.datosTransaccion!.DetalleMedicamentosRequest!.forEach((item) => {
          item.items.forEach((subItem) => {
            nuevoArray.push({
              ...item,
              items: [
                {
                  ...subItem,
                  estado:
                    this.datosTransaccion?.extraDispensaRequest?.justifica ||
                    null,
                },
              ],
            });
          });
        });
        this.store.dispatch(new setDetallesMedicamentosRequest(nuevoArray));
      }

      let respuesta = await this.realizarTransaccion();
      if (respuesta) {
        console.log('respuesta', respuesta);
        if (respuesta.EncabezadoAtencion && respuesta.EncabezadoMensaje && respuesta.EncabezadoMensaje.Rta?.CodRtaGeneral !== '000') {
          this.dialogsService.info(
            '',
            'El Afiliado ingresado no es correcto, verifique y vuelva a intentarlo.'
          );
          this.store.dispatch(new DesactivarLoading());
          return;
        }
        //Realizo prescripcion
        this.store.dispatch(new setBeneficiarioRequest({
          ApellidoBeneficiario: respuesta.EncabezadoAtencion.Beneficiario.ApellidoBeneficiario || null,
          Direccion: '' || null,
          Email: this.datosTransaccion!.extraDispensaRequest?.beneficiario_email || '',
          NombreBeneficiario: '' || null,
          NroDocBeneficiario: '' || null,
          Parentesco: '' || null,
          Telefono: '' || null,
          TipoDocBeneficiario: '' || null,
          Sexo: respuesta.EncabezadoAtencion.Beneficiario.Sexo || null,
          FechaNacimiento: respuesta.EncabezadoAtencion.Beneficiario.FechaNacimiento || null,
          PlanCredencial: respuesta.EncabezadoAtencion.Credencial.PlanCredencial || null,
        }));
        // let token = await this.transaccionesService.getTokenDispensa();
        // this.parametroPrescripcion.token = token.access_token;
        this.parametroPrescripcion.verificoPrescripcion = false;
        let rtaDispensa = await this.realizarTransaccion();
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
        if (rtaDispensa?.code == 200) {
          //console.log('RESPUESTA: ', respuesta);
          this.limpiarDatos();
          let numerosReceta = '';
          let tipoTextoMsj = 'Número de Receta: ';
          rtaDispensa.mensaje.forEach((element: any) => {
            numerosReceta += tipoTextoMsj + element.formulario_numero + ' ';
            //console.log('recetaNum: ',element.formulario_numero);
          });
          this.dialogsService.success(
            '',
            'Prescripción exitosa ' + numerosReceta
          );
        } else {
          this.limpiarDatos();
          this.dialogsService.toastError(
            'Se produjo un error, vuelva a intentarlo más tarde'
          );
        }
      } else {
        this.store.dispatch(new DesactivarLoading());
        this.limpiarDatos();
        this.dialogsService.toastError(
          'Se produjo un error, vuelva a intentarlo más tarde'
        );
      }
    }
  }

  realizarTransaccion() {
    //Esto es en el caso de realizar un receta electronico verfico antes
    return this.transaccionesService
      .realizarTransaccion(
        this.datosInicio!,
        this.datosTransaccion!,
        this.parametroPrescripcion
      )
      .toPromise()
      .catch((error) => {
        this.limpiarDatos();
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
        this.globalService.manageError(error);
      });
  }

  limpiarDatos() {

    // this.store.dispatch(new unsetDatosInicio());
    this.store.dispatch(new unsetCredencialRequest());
    this.store.dispatch(new unsetFinanciadorRequest());
    this.store.dispatch(new unsetPrestadorRequest());
    this.store.dispatch(new unsetTransaccioRequest());
    this.store.dispatch(new unsetDatosTransacciones());
    this.store.dispatch(new unsetEstadoFormDatos());
    this.store.dispatch(new unsetRecetasRequest());
    this.store.dispatch(new unsetEsRecetaRequest());
    this.inputAgendaComponent.limpiarDatos();
    this.datosInicialesDesplegable.showBar();
  }

  agregarOperacion() {
    this.router.navigateByUrl('perfil/ordenartransacciones');
  }

  openNewChangesModal() {
    this.dialogsService.newChangesModal();
  }
}

<div [ngClass]="{'containerN containerN-dashboard': !esTurnea}">
      <app-navbar-windows class="app-navbar-windows"></app-navbar-windows>
      <main class="cardN-dashboard ">

        <div class="animated slideInLeft" [class.oculto]="globalService.ocultarMenu" *ngIf="!esTurnea">
          <div class="card__columnL card__columnL-dashboard">
            <div class="card__columnL-up">
              <figure class="card__logoNuvalidC">
                <img src="./assets/design/logoNuvalid.svg" alt="Logo Nuvalid"
                  class="card__logoNuvalid card__logoNuvalid-dashboard">
                <!-- <div id="logoN"> </div> -->
              </figure>
              <div class="card__columnL-upIcons">
                <figure class="container_iconsColumnL" (click)="inicio()">
                  <a><img src="./assets/design/home.svg" alt="Nuvalid Ícono Home" title="Inicio"></a>
                </figure>
                <figure class="container_iconsColumnL" (click)="ticket()">
                  <a><img class="iconSize" src="./assets/design/receipt.svg" alt="Nuvalid Ícono Tickets" title="Tickets"></a>
                </figure>
                <figure class="container_iconsColumnL" (click)="agenda()">
                  <a><img src="./assets/design/agenda.svg" alt="Nuvalid Ícono Agenda" title="Agenda"></a>
                </figure>
                <figure class="container_iconsColumnL" (click)="perfil()">
                  <a><img class="iconSize" src="./assets/design/perfil.svg" alt="Nuvalid Ícono Perfil" title="Perfil"></a>
                </figure>
                <figure class="container_iconsColumnL" (click)="sugerencia()">
                  <a><img style="width: 30px;" src="./assets/design/suggestions-nuvalid.svg" alt="Nuvalid Ícono Sugerencias"
                      title="Sugerencias"></a>
                </figure>
                <figure class="container_iconsColumnL" (click)="turnea()">
                  <a><img style="width: 30px;" src="./assets/design/calendar.svg" alt="Nuvalid Ícono Turnos"
                      title="Asignar turnos"></a>
                </figure>
                <div class="lineHDashboard"></div>
                <figure class="card__columnL-upIconsS container_iconsColumnL" (click)="salir()">
                  <a><img src="./assets/design/logout.svg" alt="Nuvalid Ícono Cerrar Sesión" title="Cerrar sesión"></a>
                </figure>
              </div>
            </div>

            <div class="contianerDown">
              <figure class=" card__phoneC">
                <a id="phone" title="HelpDesk">
                  <img src="./assets/design/phone.svg" alt="Nuvalid Ícono Celular" class="card__phone" (click)="contacto()">
                </a>
              </figure>
              <p class="versionP pb-2">Versión {{versionActual}}</p>
            </div>
          </div>
        </div>

        <div *ngIf='!esTurnea' class="card__columnR card__columnR-dashboard" [ngStyle]="isElectron ? {'padding-top': '0%'} : {}">
            <app-navbar *ngIf="!isElectron"></app-navbar>
            <router-outlet></router-outlet>
        </div>

        <div *ngIf='esTurnea' class="card__columnR card__columnR-dashboard" style="padding:0%;overflow: hidden">
          <app-navbar *ngIf="!isElectron"></app-navbar>
          <!-- <router-outlet></router-outlet> -->
          <app-turnea></app-turnea>
        </div>
      </main>
</div>

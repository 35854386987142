import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueCuit'
})
export class UniqueCuitPipe implements PipeTransform {
  transform(prestadores: any[]): any[] {
    if (!prestadores) {
      return [];
    }

    return prestadores.filter((prestador, index, self) =>
      index === self.findIndex(p => p.cuit === prestador.cuit)
    );
  }
}
